import React, { ChangeEvent } from 'react';
import { Form, FormInstance, Input, Select } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import isEqual from 'lodash.isequal';
import parse from 'html-react-parser';
import sanitize from 'sanitize-html';
import {
  EPalette,
  EQuestionForm,
  EQuestionPossibleType,
  EQuestionType,
  ERelationConditionType,
  ERelationType,
  ETextOrSkipQuestionInputType,
  IOptionChange,
  IViewElementParams,
} from 'common/models/questions';
import {
  MultipleType,
  PeriodType,
  SingleChoiceType,
  ListType,
  TextareaType,
  SliderType,
  TextOrSkip,
  TimePicker,
} from 'common/components/TypeComponents';
import { NRSType } from 'common/components/NRS';
import {
  IQuestionDto,
  IQuestionRelationsDto,
  IQuestionTranslation,
  IQuestionTranslationValue,
  IQuestionValue,
  IQuestionViewConsentForm,
  ISliderOptionsDto,
  QuestionWithTranslation,
} from 'entities/Questions/questions.model';
import { IFormState } from 'entities/UI/ui.models';
import { IQuestionsConnectedProps } from 'entities/Questions/questions.communication';
import { IUIConnectedProps } from 'entities/UI/ui.communication';
import { IAssessmentsConnectedProps } from 'entities/Assessments/assessments.communication';

const { Option } = Select;

export const getOptions = (value: IQuestionValue | IQuestionTranslationValue, isAnotherLang?: boolean) => (
  <Option
    key={isAnotherLang ? (value as IQuestionTranslationValue).questionValueId : (value as IQuestionValue).id}
    value={value.value}
  >
    {value.value}
  </Option>
);

export const renderQuestionValues = (
  question: QuestionWithTranslation,
  handleTextareaChange: (event: ChangeEvent<HTMLTextAreaElement>) => void,
  isAnotherLang: boolean
) => {
  const orientation = question.view?.orientation;
  const className = `questions__form_common-btns ${
    orientation === 'HORIZONTAL' ? 'questions__form_common-btns--horizontal' : ''
  }`;
  switch (true) {
    case question.type === EQuestionType.singleChoice:
      return <SingleChoiceType className={className} question={question} isAnotherLang={isAnotherLang} />;
    case question.type === EQuestionType.slider:
      return <SliderType question={question} isAnotherLang={isAnotherLang} orientation={orientation} />;
    case question.type === EQuestionType.textarea:
      return <TextareaType question={question} isAnotherLang={isAnotherLang} handleTextareaChange={handleTextareaChange} />;
    case question.type === EQuestionType.medications:
    case question.type === EQuestionType.signature:
    case question.type === EQuestionType.date:
    case question.type === EQuestionType.generalPainArea:
    case question.type === EQuestionType.detailedPainArea:
    case question.type === EQuestionType.selectPainArea:
    case question.type === EQuestionType.tagEditor:
      return <div className="questions__form_common-no-preview">No preview available</div>;
    case question.type === EQuestionType.multipleChoice:
      return <MultipleType className={className} question={question} isAnotherLang={isAnotherLang} />;
    case question.type === EQuestionType.period:
      return <PeriodType getOptions={getOptions} question={question} isAnotherLang={isAnotherLang} />;
    case question.type === EQuestionType.nrs:
      return <NRSType question={question} isAnotherLang={isAnotherLang} />;
    case question.type === EQuestionType.consentForm:
      return (
        <Checkbox className="questions__form_common-consent">
          {isAnotherLang && question?.translations?.view?.checkboxTitle
            ? question.translations.view.checkboxTitle
            : (question.view as IQuestionViewConsentForm).checkboxTitle}
        </Checkbox>
      );
    case question.type === EQuestionType.list:
      return <ListType question={question} isAnotherLang={isAnotherLang} />;
    case question.type === EQuestionType.textOrSkip:
      return <TextOrSkip question={question} isAnotherLang={isAnotherLang} />;
    case question.type === EQuestionType.timePicker:
      return <TimePicker question={question} isAnotherLang={isAnotherLang} />;
    default:
      return null;
  }
};

export const visibilityPredicatesHelper = (parent: IQuestionDto | undefined) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (parent?.type) {
    case EQuestionType.textarea:
    case EQuestionType.markdown:
      return (
        <>
          <Option value={ERelationConditionType.IS}>Is</Option>
          <Option value={ERelationConditionType.IS_NOT}>Is not</Option>
          <Option value={ERelationConditionType.EMPTY}>Empty</Option>
          <Option value={ERelationConditionType.NOT_EMPTY}>Not empty</Option>
        </>
      );
    default:
      return (
        <>
          <Option value={ERelationConditionType.IS}>Is</Option>
          <Option value={ERelationConditionType.IS_NOT}>Is not</Option>
        </>
      );
  }
};

export const visibilityParentValuesHelper = (
  selectedQuestionRelation: IQuestionRelationsDto,
  parent: IQuestionDto | undefined,
  id: string
) => {
  switch (parent?.type) {
    case EQuestionType.textarea:
    case EQuestionType.markdown:
      return (
        selectedQuestionRelation && (
          <Form.Item
            name={[EQuestionForm.RelationValue, id, 'value']}
            label="Value"
            initialValue={selectedQuestionRelation.value?.value}
          >
            <Input value={selectedQuestionRelation.value?.value || ''} placeholder="Value" />
          </Form.Item>
        )
      );
    case EQuestionType.slider:
      const options = parent.options as ISliderOptionsDto;

      return (
        selectedQuestionRelation && (
          <Form.Item
            name={[EQuestionForm.RelationValue, id, 'value']}
            label="Value"
            initialValue={selectedQuestionRelation.value?.value}
          >
            <Input
              value={selectedQuestionRelation.value?.value || ''}
              placeholder="Value"
              type="number"
              min={options.min}
              max={options.max}
              step={options.step}
            />
          </Form.Item>
        )
      );
    default:
      return (
        <Form.Item
          name={[EQuestionForm.RelationValue, id, 'value']}
          label="Value"
          initialValue={selectedQuestionRelation.value?.value || ''}
        >
          <Select value={selectedQuestionRelation.value?.value}>{parent?.values.map((value) => getOptions(value))}</Select>
        </Form.Item>
      );
  }
};

export const getUniqueOrder = (questions: IQuestionDto[]): number => {
  const takenValues = questions.map((question: IQuestionDto) => question.order).sort((a: number, b: number) => (a < b ? -1 : 1));
  let candidate = 1;

  takenValues.forEach((value: number) => {
    if (candidate === value) {
      candidate = value + 1;
    }
  });

  return candidate;
};

export const checkPossibility = (selectedQuestion: IQuestionDto, possibleType: EQuestionPossibleType) => {
  switch (possibleType) {
    case EQuestionPossibleType.UniqueAppearance:
      return (
        selectedQuestion.type === EQuestionType.singleChoice ||
        selectedQuestion.type === EQuestionType.multipleChoice ||
        selectedQuestion.type === EQuestionType.slider ||
        selectedQuestion.type === EQuestionType.consentForm ||
        selectedQuestion.type === EQuestionType.textOrSkip
      );
    case EQuestionPossibleType.Options:
      return (
        selectedQuestion.type === EQuestionType.period ||
        selectedQuestion.type === EQuestionType.singleChoice ||
        selectedQuestion.type === EQuestionType.multipleChoice
      );
    case EQuestionPossibleType.OtherOption:
      return selectedQuestion.type === EQuestionType.singleChoice || selectedQuestion.type === EQuestionType.multipleChoice;
    case EQuestionPossibleType.NoneOfTheAboveOption:
      return selectedQuestion.type === EQuestionType.multipleChoice;
    default:
      return false;
  }
};

export const removeQuestion = async (params: {
  uiFormState: IFormState;
  questions: IQuestionDto[];
  updateQuestionsModel: IQuestionsConnectedProps['updateQuestionsModel'];
  deleteQuestionsModel: IQuestionsConnectedProps['deleteQuestionsModel'];
  setSelectedQuestionUiFormState: IUIConnectedProps['setSelectedQuestionUiFormState'];
  getAssessmentsCollection: IAssessmentsConnectedProps['getAssessmentsCollection'];
  assessmentId: string;
  getQuestionsCollection: IQuestionsConnectedProps['getQuestionsCollection'];
}) => {
  const questionId = params.uiFormState.selectedQuestion;
  if (questionId) {
    // If there are any dependencies on the question being removed - remove them

    params
      .deleteQuestionsModel({ questionId })
      .then(async () => {
        const deletedQuestion = params.questions.find((question) => question.id === questionId);

        if (deletedQuestion) {
          const updateQuestionsRequests: Promise<void>[] = [];

          params.questions.forEach((question, index) => {
            if (question.order > deletedQuestion.order && question.label !== deletedQuestion.label) {
              updateQuestionsRequests.push(
                params.updateQuestionsModel({
                  questionId: question.id,
                  body: {
                    ...question,
                    order: index - 1,
                  },
                })
              );
            }
          });

          await Promise.all(updateQuestionsRequests).then(() => {
            params.setSelectedQuestionUiFormState({ selectedQuestion: null });
            params.getAssessmentsCollection({});
          });
        }
      })
      .finally(() => {
        if (params.getQuestionsCollection && params.assessmentId) {
          params.getQuestionsCollection({ assessmentId: params.assessmentId });
        }
      });
  }
};

// Functions for change values of the selected question

export const handleNRSOptionsChange = (
  selectedQuestion: IQuestionDto | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  params: { key: string; value: string | boolean }
) => {
  if (selectedQuestion) {
    if (selectedQuestion.view) {
      const view = selectedQuestion.view;
      const updatedView = {
        ...view,
        [params.key]: params.value,
      };

      if (params.key === EQuestionForm.ShowDescription) {
        // @ts-ignore
        delete updatedView.description;
      }

      setSelectedQuestion({
        ...selectedQuestion,
        view: updatedView,
      });
    } else {
      const view = {
        palette: params.key === EQuestionForm.Palette ? (params.value as EPalette) : EPalette.Monochrome,
        showFaceImages: params.key === EQuestionForm.ShowFaceImages ? (params.value as boolean) : false,
        showDescription: params.key === EQuestionForm.ShowDescription ? (params.value as boolean) : false,
      };

      setSelectedQuestion({ ...selectedQuestion, view });
    }
  }
};

export const handleSliderDescriptionChange = (
  selectedQuestion: IQuestionDto | null,
  translatedSelectedQuestion: IQuestionTranslation | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  setTranslatedQuestion: (value: IQuestionTranslation) => void,
  isAnotherLang: boolean,
  params: IViewElementParams
) => {
  if (selectedQuestion && !isAnotherLang) {
    setSelectedQuestion({
      ...selectedQuestion,
      view: {
        ...selectedQuestion.view,
        [params.key]: { label: params.value.value },
      },
    });
  } else if (translatedSelectedQuestion) {
    setTranslatedQuestion({
      ...translatedSelectedQuestion,
      view: { ...translatedSelectedQuestion.view, [params.key]: { label: params.value.value as string } },
    });
  }
};

export const handleRelationValueChange = (
  selectedQuestion: IQuestionDto | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  option: IOptionChange
) => {
  if (selectedQuestion && selectedQuestion.parents) {
    const newRelations = selectedQuestion.parents.map((relation: IQuestionRelationsDto) => {
      if (option.id === relation.id) {
        relation.value = {
          value: option.value.value as string,
          condition: relation.value?.condition || 'IS',
        };
      }
      return relation;
    });
    setSelectedQuestion({ ...selectedQuestion, parents: newRelations });
  }
};
export const handleRelationConditionChange = (
  selectedQuestion: IQuestionDto | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  option: IOptionChange
) => {
  if (selectedQuestion && selectedQuestion.parents) {
    const newRelations = selectedQuestion.parents.map((relation: IQuestionRelationsDto) => {
      if (option.id === relation.id) {
        relation.value = {
          value: relation.value?.value || '',
          condition: option.value.value as ERelationConditionType,
        };
      }
      return relation;
    });
    setSelectedQuestion({ ...selectedQuestion, parents: newRelations });
  }
};
export const handleRelationParentChange = (
  selectedQuestion: IQuestionDto | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  option: IOptionChange
) => {
  if (selectedQuestion && selectedQuestion.parents) {
    const newRelations = selectedQuestion.parents.map((relation: IQuestionRelationsDto) => {
      if (option.id === relation.id) {
        relation.parentQuestionId = option.value.value as string;
      }
      return relation;
    });
    setSelectedQuestion({ ...selectedQuestion, parents: newRelations });
  }
};
export const handleRelationTypeChange = (
  selectedQuestion: IQuestionDto | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  option: IOptionChange,
  form: FormInstance<any>
) => {
  if (selectedQuestion && selectedQuestion.parents) {
    const firstType =
      selectedQuestion.parents?.length > 0
        ? selectedQuestion.parents[0].type === ERelationType.Visibility
          ? 'placeholder'
          : 'visibility'
        : 'visibility';
    const secondType = firstType === ERelationType.Visibility ? ERelationType.Placeholder : ERelationType.Visibility;
    if (selectedQuestion.parents?.length > 1) {
      form.setFields([
        {
          name: [EQuestionForm.RelationType, selectedQuestion.parents[1].id, 'value'],
          value: secondType,
        },
      ]);
    }
    const newRelations = selectedQuestion.parents.map((relation: IQuestionRelationsDto, index) => {
      if (option.id === relation.id) {
        if (option.value.value === ERelationType.Placeholder) {
          relation.value = undefined;
        } else {
          relation.value = {
            value: '',
            condition: 'IS',
          };
        }
        relation.type = option.value.value as ERelationType;
      }
      if (index === 1) {
        relation.type = secondType;
      }
      return relation;
    });
    setSelectedQuestion({ ...selectedQuestion, parents: newRelations });
  }
};
export const handleAddOtherPeriod = (
  selectedQuestion: IQuestionDto | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  option: IOptionChange | undefined,
  form: FormInstance<any>
) => {
  form.resetFields([EQuestionForm.SelectedDefaultPeriod]);
  if (selectedQuestion) {
    if (!option?.value) {
      selectedQuestion.values[0].isDefault = true;
      form.setFieldsValue({ [EQuestionForm.SelectedDefaultPeriod]: selectedQuestion.values[0].value });
    }
    const newValues: IQuestionValue[] = selectedQuestion.values.map((questionValue: IQuestionValue) => {
      if (option && questionValue.id === option.id) {
        questionValue.isDefault = false;
      }
      return questionValue;
    });
    setSelectedQuestion({ ...selectedQuestion, values: newValues });
  }
};
export const handleDefaultSelectedChange = (
  selectedQuestion: IQuestionDto | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  value: string
) => {
  if (selectedQuestion) {
    const newValues: IQuestionValue[] = selectedQuestion.values.map((questionValue: IQuestionValue) => {
      if (questionValue.value === value) {
        questionValue.isDefault = true;
      } else {
        questionValue.isDefault = false;
      }
      return questionValue;
    });
    setSelectedQuestion({ ...selectedQuestion, values: newValues });
  }
};
export const handleAuxiliaryOptionValueChange = (
  selectedQuestion: IQuestionDto | null,
  translatedSelectedQuestion: IQuestionTranslation | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  setTranslatedQuestion: (value: IQuestionTranslation) => void,
  isAnotherLang: boolean,
  option: IOptionChange
) => {
  if (selectedQuestion && !isAnotherLang) {
    const newValues = selectedQuestion.values.map((value: IQuestionValue) => {
      if (value.id === option.id && 'value' in option.value) {
        value.value = option.value.value || '';
      }
      return value;
    });
    setSelectedQuestion({ ...selectedQuestion, values: newValues });
  } else if (translatedSelectedQuestion) {
    const newValues = translatedSelectedQuestion?.values?.map((value) => {
      if (value.questionValueId === option.id && 'value' in option.value) {
        value.value = option.value.value || '';
      }
      return value;
    });

    setTranslatedQuestion({ ...translatedSelectedQuestion, values: newValues });
  }
};
export const handleOptionChange = (
  selectedQuestion: IQuestionDto | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  isAnotherLang: boolean,
  option: IOptionChange
) => {
  if (selectedQuestion && !isAnotherLang) {
    const newValues = selectedQuestion.values.map((value: IQuestionValue) => {
      if (value.id === option.id) {
        if ('value' in option.value) {
          value.value = option.value.value || '';
        } else if ('score' in option.value) {
          value.score = option.value.score ? parseInt(option.value.score) : undefined;
        }
      }
      return value;
    });
    setSelectedQuestion({ ...selectedQuestion, values: newValues });
  }
};
export const handleTypeChange = (
  selectedQuestion: IQuestionDto | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  setValuesCopy: (value: IQuestionValue[]) => void,
  value: string,
  valuesCopy: IQuestionValue[],
  questionsCopy: IQuestionDto[] | undefined,
  form: FormInstance<any>
) => {
  if (selectedQuestion) {
    if (
      value !== EQuestionType.slider &&
      value !== EQuestionType.textarea &&
      value !== EQuestionType.markdown &&
      value !== EQuestionType.period
    ) {
      const values = valuesCopy.length
        ? valuesCopy
        : questionsCopy?.find((question: IQuestionDto) => question.id === selectedQuestion.id)?.values || [];
      const nrsDefaultData =
        value === EQuestionType.nrs
          ? {
              showDescription: true,
              showFaceImages: true,
            }
          : {};
      const selectedQuestionCopy = {
        ...selectedQuestion,
        view: {
          ...selectedQuestion.view,
          ...nrsDefaultData,
        },
      };
      // @ts-ignore
      delete selectedQuestionCopy.options.max;
      // @ts-ignore
      delete selectedQuestionCopy.options.min;
      // @ts-ignore
      delete selectedQuestionCopy.options.step;

      if (value === EQuestionType.consentForm) {
        form.setFieldsValue({ [EQuestionForm.Orientation]: 'checkbox' });
      }

      if (value === EQuestionType.textOrSkip) {
        form.setFieldsValue({ [EQuestionForm.Orientation]: ETextOrSkipQuestionInputType.Textarea });
      }

      setSelectedQuestion({
        ...selectedQuestionCopy,
        values: value === EQuestionType.nrs ? [] : values,
        type: value as EQuestionType,
        view:
          value === EQuestionType.consentForm
            ? {
                ...selectedQuestionCopy.view,
                type: 'checkbox',
              }
            : value === EQuestionType.textOrSkip
            ? {
                ...selectedQuestionCopy.view,
                inputType: ETextOrSkipQuestionInputType.Textarea,
              }
            : {
                ...selectedQuestionCopy.view,
              },
      });
    } else {
      setValuesCopy(selectedQuestion.values);
      setSelectedQuestion({
        ...selectedQuestion,
        values: [],
        type: value,
        options: {
          ...selectedQuestion.options,
          ...(value === EQuestionType.slider && { min: 0, max: 10, step: 1 }),
        },
        view: {
          ...selectedQuestion.view,
          ...(value === EQuestionType.slider && { isCurrentValueHidden: false }),
        },
      });
    }
  }
};
export const handleKeyChange = (
  selectedQuestion: IQuestionDto | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  key: string
) => {
  if (selectedQuestion) {
    setSelectedQuestion({ ...selectedQuestion, key });
  }
};
export const handleLabelChange = (
  selectedQuestion: IQuestionDto | null,
  translatedSelectedQuestion: IQuestionTranslation | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  setTranslatedQuestion: (value: IQuestionTranslation) => void,
  isAnotherLang: boolean,
  label: string
) => {
  if (selectedQuestion && !isAnotherLang) {
    setSelectedQuestion({ ...selectedQuestion, label });
  } else if (translatedSelectedQuestion) {
    setTranslatedQuestion({ ...translatedSelectedQuestion, label });
  }
};
export const handleAddOnChange = (
  selectedQuestion: IQuestionDto | null,
  translatedSelectedQuestion: IQuestionTranslation | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  setTranslatedQuestion: (value: IQuestionTranslation) => void,
  isAnotherLang: boolean,
  addOn: string
) => {
  if (selectedQuestion && !isAnotherLang) {
    setSelectedQuestion({ ...selectedQuestion, view: { ...selectedQuestion.view, addOn } });
  } else if (translatedSelectedQuestion) {
    setTranslatedQuestion({ ...translatedSelectedQuestion, view: { ...translatedSelectedQuestion.view, addOn } });
  }
};
export const handleConsentTypeChange = (
  selectedQuestion: IQuestionDto | null,
  translatedSelectedQuestion: IQuestionTranslation | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  setTranslatedQuestion: (value: IQuestionTranslation) => void,
  isAnotherLang: boolean,
  checkboxTitle: string
) => {
  if (selectedQuestion && !isAnotherLang) {
    setSelectedQuestion({
      ...selectedQuestion,
      view: {
        ...selectedQuestion.view,
        checkboxTitle,
      },
    });
  } else if (translatedSelectedQuestion) {
    setTranslatedQuestion({
      ...translatedSelectedQuestion,
      view: { ...translatedSelectedQuestion.view, checkboxTitle },
    });
  }
};
export const handleSubTextChange = (
  selectedQuestion: IQuestionDto | null,
  translatedSelectedQuestion: IQuestionTranslation | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  setTranslatedQuestion: (value: IQuestionTranslation) => void,
  isAnotherLang: boolean,
  subText: string
) => {
  if (selectedQuestion && !isAnotherLang) {
    setSelectedQuestion({ ...selectedQuestion, view: { ...selectedQuestion.view, subText } });
  } else if (translatedSelectedQuestion) {
    setTranslatedQuestion({ ...translatedSelectedQuestion, view: { ...translatedSelectedQuestion.view, subText } });
  }
};
export const handleTranslatedOptionChange = (
  translatedSelectedQuestion: IQuestionTranslation | null,
  setTranslatedQuestion: (value: IQuestionTranslation) => void,
  option: IOptionChange
) => {
  if (translatedSelectedQuestion && translatedSelectedQuestion.values) {
    const newValues = translatedSelectedQuestion.values.map((value) => {
      if (value.questionValueId === option.id) {
        value.value = (option.value as string) || '';
      }

      return value;
    });

    setTranslatedQuestion({ ...translatedSelectedQuestion, values: newValues });
  }
};
export const handleCustomMarkdownChange = (
  selectedQuestion: IQuestionDto | null,
  translatedSelectedQuestion: IQuestionTranslation | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  setTranslatedQuestion: (value: IQuestionTranslation) => void,
  isAnotherLang: boolean,
  customMarkdown: string
) => {
  if (selectedQuestion && !isAnotherLang) {
    setSelectedQuestion({
      ...selectedQuestion,
      options: {
        ...selectedQuestion.options,
        customMarkdown,
      },
    });
  } else if (translatedSelectedQuestion) {
    setTranslatedQuestion({
      ...translatedSelectedQuestion,
      options: { ...translatedSelectedQuestion.options, customMarkdown },
    });
  }
};
export const handleListItemTitleChange = (
  selectedQuestion: IQuestionDto | null,
  translatedSelectedQuestion: IQuestionTranslation | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  setTranslatedQuestion: (value: IQuestionTranslation) => void,
  isAnotherLang: boolean,
  listItemTitle: string
) => {
  if (selectedQuestion && !isAnotherLang) {
    setSelectedQuestion({
      ...selectedQuestion,
      view: {
        ...selectedQuestion.view,
        listItemTitle,
      },
    });
  } else if (translatedSelectedQuestion) {
    setTranslatedQuestion({ ...translatedSelectedQuestion, view: { ...translatedSelectedQuestion.view, listItemTitle } });
  }
};
export const handleListItemPlaceholderChange = (
  selectedQuestion: IQuestionDto | null,
  translatedSelectedQuestion: IQuestionTranslation | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  setTranslatedQuestion: (value: IQuestionTranslation) => void,
  isAnotherLang: boolean,
  listItemPlaceholder: string
) => {
  if (selectedQuestion && !isAnotherLang) {
    setSelectedQuestion({
      ...selectedQuestion,
      view: {
        ...selectedQuestion.view,
        listItemPlaceholder,
      },
    });
  } else if (translatedSelectedQuestion) {
    setTranslatedQuestion({
      ...translatedSelectedQuestion,
      view: { ...translatedSelectedQuestion.view, listItemPlaceholder },
    });
  }
};
export const handlePlaceholderChange = (
  selectedQuestion: IQuestionDto | null,
  translatedSelectedQuestion: IQuestionTranslation | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  setTranslatedQuestion: (value: IQuestionTranslation) => void,
  isAnotherLang: boolean,
  placeholder: string
) => {
  if (selectedQuestion && !isAnotherLang) {
    setSelectedQuestion({
      ...selectedQuestion,
      view: {
        ...selectedQuestion.view,
        placeholder,
      },
    });
  } else if (translatedSelectedQuestion) {
    setTranslatedQuestion({
      ...translatedSelectedQuestion,
      view: { ...translatedSelectedQuestion.view, placeholder },
    });
  }
};
// set selected question style
export const setSelectedQuestionStyles = (selectedQuestion: IQuestionDto | null, id: string): string => {
  let className = 'questions__form_common-treatment';
  if (id === selectedQuestion?.id) {
    className += ' questions__form_common-treatment--selected';
  }

  return className;
};

export const handleCheckboxTextChange = (
  selectedQuestion: IQuestionDto | null,
  translatedSelectedQuestion: IQuestionTranslation | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  setTranslatedQuestion: (value: IQuestionTranslation) => void,
  isAnotherLang: boolean,
  checkboxText: string
) => {
  if (selectedQuestion && !isAnotherLang) {
    setSelectedQuestion({
      ...selectedQuestion,
      view: {
        ...selectedQuestion.view,
        checkboxText,
      },
    });
  } else if (translatedSelectedQuestion) {
    setTranslatedQuestion({
      ...translatedSelectedQuestion,
      view: { ...translatedSelectedQuestion.view, checkboxText },
    });
  }
};
export const handlePeriodPlaceholderChange = (
  selectedQuestion: IQuestionDto | null,
  translatedSelectedQuestion: IQuestionTranslation | null,
  setSelectedQuestion: (value: IQuestionDto) => void,
  setTranslatedQuestion: (value: IQuestionTranslation) => void,
  isAnotherLang: boolean,
  key: string,
  value: string
) => {
  if (selectedQuestion && !isAnotherLang) {
    setSelectedQuestion({
      ...selectedQuestion,
      view: {
        ...selectedQuestion.view,
        [key]: value,
      },
    });
  } else if (translatedSelectedQuestion) {
    setTranslatedQuestion({
      ...translatedSelectedQuestion,
      view: { ...translatedSelectedQuestion.view, [key]: value },
    });
  }
};

export const findModifiedQuestions = (originalQuestions: IQuestionDto[], questionsCopy: IQuestionDto[]): IQuestionDto[] => {
  if (questionsCopy.some((question, index) => question.order !== index)) {
    return questionsCopy.map((question, index) => ({ ...question, order: index }));
  }

  return questionsCopy.filter((questionCopy) => {
    const originalQuestion = originalQuestions.find((question) => question.id === questionCopy.id);

    return originalQuestion && !isEqual(originalQuestion, questionCopy);
  });
};

export const parseValueToHtml = (value = '') => {
  return parse(
    sanitize(value, {
      allowedAttributes: {
        '*': ['style', 'class'],
      },
      parseStyleAttributes: false,
    })
  );
};
