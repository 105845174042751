import { Button, Modal } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeQuestion } from 'common/helpers/questions.helper';
import { communicationUI, IUIConnectedProps } from 'entities/UI/ui.communication';
import { EActionType } from 'entities/UI/ui.models';
import { communicationQuestions, IQuestionsConnectedProps } from 'entities/Questions/questions.communication';
import { communicationAssessments, IAssessmentsConnectedProps } from 'entities/Assessments/assessments.communication';

type AllProps = IUIConnectedProps & IQuestionsConnectedProps & IAssessmentsConnectedProps;

const UnsavedItemsModalComponent: React.FC<AllProps> = (props) => {
  const {
    uiUnsavedItemsModal,
    hideUiUnsavedItemsModal,
    uiFormState,
    questionsCollection,
    updateQuestionsModel,
    setSelectedQuestionUiFormState,
    getAssessmentsCollection,
    deleteQuestionsModel,
    resetUiUnsavedItemsModal,
    showUiAddQuestionModal,
    getQuestionsCollection,
  } = props;
  const isVisible = useMemo(() => uiUnsavedItemsModal.data?.isVisible, [uiUnsavedItemsModal]);
  const canSave = useMemo(() => !uiFormState.data?.dependencyConflicts, [uiFormState]);
  const navigate = useNavigate();

  const onOk = useCallback(() => {
    hideUiUnsavedItemsModal();
  }, []);

  const onCancel = useCallback(async () => {
    if (uiUnsavedItemsModal.data?.actionType === EActionType.Navigate && uiUnsavedItemsModal.data?.navigateTo) {
      navigate(uiUnsavedItemsModal.data?.navigateTo);
    }

    if (uiUnsavedItemsModal.data?.actionType === EActionType.RemoveQuestion && uiFormState.data && questionsCollection.data) {
      await removeQuestion({
        uiFormState: uiFormState.data,
        questions: questionsCollection.data.questions,
        updateQuestionsModel,
        deleteQuestionsModel,
        setSelectedQuestionUiFormState,
        getAssessmentsCollection,
        assessmentId: questionsCollection.data.id,
        getQuestionsCollection,
      });
    }

    if (uiUnsavedItemsModal.data?.actionType === EActionType.AddQuestion) {
      showUiAddQuestionModal();
    }

    resetUiUnsavedItemsModal();
  }, [navigate, uiUnsavedItemsModal, uiFormState.data, questionsCollection.data]);

  const footer = (
    <>
      <Button onClick={onOk} form="form" htmlType="submit" type="primary" disabled={!canSave}>
        Save
      </Button>
      <Button onClick={onCancel}>Discard</Button>
    </>
  );

  return (
    <Modal
      visible={isVisible}
      footer={footer}
      onCancel={() => {
        resetUiUnsavedItemsModal();
        hideUiUnsavedItemsModal();
      }}
    >
      {canSave ? (
        <span>There are unsaved changes on this form. Would you like to save them before proceeding?</span>
      ) : (
        <span className="error_text">
          There are errors on this form. If you want to save your changes, close this modal and resolve the conflicts first.
        </span>
      )}
    </Modal>
  );
};

export const UnsavedItemsModal = communicationAssessments.injector(
  communicationQuestions.injector(communicationUI.injector(UnsavedItemsModalComponent))
);
