import React, { FC, useState } from 'react';
import { Checkbox, InputNumber } from 'antd';
import { MINUTE_LIMIT_MAX, TIME_LIMIT_MIN } from 'common/config/config';
import { IQuestionViewTextOrSkip, QuestionWithTranslation } from 'entities/Questions/questions.model';

interface IComponentProps {
  question: QuestionWithTranslation;
  isAnotherLang: boolean;
}

export const TimePicker: FC<IComponentProps> = ({ question, isAnotherLang }) => {
  const [hours, setHours] = useState<number | undefined>();
  const [minutes, setMinutes] = useState<number | undefined>();
  const [checked, setChecked] = useState<boolean>(false);

  return (
    <div className="questions__form_common-time-picker">
      <div className="questions__form_common-time-picker-container">
        <div className="questions__form_common-time-picker-input-container">
          <InputNumber controls={false} min={TIME_LIMIT_MIN} value={hours} onChange={setHours} disabled={checked} />

          <div>hours</div>
        </div>

        <div className="questions__form_common-time-picker-input-container">
          <InputNumber
            controls={false}
            min={TIME_LIMIT_MIN}
            max={MINUTE_LIMIT_MAX}
            value={minutes}
            onChange={setMinutes}
            disabled={checked}
          />

          <div>minutes</div>
        </div>
      </div>

      <Checkbox
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
        disabled={(hours !== undefined && hours !== null) || (minutes !== undefined && minutes !== null)}
      >
        {isAnotherLang && question?.translations?.view?.checkboxText
          ? question.translations.view.checkboxText
          : (question.view as IQuestionViewTextOrSkip).checkboxText}
      </Checkbox>
    </div>
  );
};
