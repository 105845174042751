/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/max-switch-cases */
import React, { useCallback, useEffect, useState, useMemo, useRef, ChangeEvent } from 'react';
import { Button, Checkbox, Empty, Form, Input, Radio, Select, Switch } from 'antd';
import { v4 as uuid } from 'uuid';
import isEqual from 'lodash.isequal';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { LeftPanel } from 'common/components/LeftPanel';
import {
  getOptions,
  removeQuestion,
  renderQuestionValues,
  visibilityPredicatesHelper,
  visibilityParentValuesHelper,
  handleNRSOptionsChange,
  handleSliderDescriptionChange,
  handleRelationValueChange,
  handleRelationConditionChange,
  handleRelationParentChange,
  handleRelationTypeChange,
  handleAddOtherPeriod,
  handleDefaultSelectedChange,
  handleAuxiliaryOptionValueChange,
  handleOptionChange,
  handleTypeChange,
  handleLabelChange,
  handleKeyChange,
  setSelectedQuestionStyles,
  handleAddOnChange,
  handleConsentTypeChange,
  handleSubTextChange,
  handleTranslatedOptionChange,
  handleCustomMarkdownChange,
  handlePlaceholderChange,
  handleListItemTitleChange,
  handleListItemPlaceholderChange,
  checkPossibility,
  handleCheckboxTextChange,
  handlePeriodPlaceholderChange,
  findModifiedQuestions,
  parseValueToHtml,
} from 'common/helpers/questions.helper';
import {
  EQuestionForm,
  EQuestionFormat,
  EQuestionOrientation,
  EQuestionPossibleType,
  EQuestionType,
  EQuestionTypeTitle,
  ERelationType,
  EReorderType,
  ESkipOption,
  ETextOrSkipQuestionInputType,
} from 'common/models/questions';
import { ENavigationType } from 'common/models/general';
import { ETranslations, ETranslationsTitle } from 'common/models/translations';
import { communicationAssessments, IAssessmentsConnectedProps } from 'entities/Assessments/assessments.communication';
import { communicationQuestions, IQuestionsConnectedProps } from 'entities/Questions/questions.communication';
import { IAssessmentDto, IAssessmentTranslation } from 'entities/Assessments/assessments.model';
import {
  IQuestionDto,
  IQuestionOptionsDto,
  IQuestionRelationsDto,
  IQuestionTranslation,
  IQuestionTranslationValue,
  IQuestionValue,
  IQuestionViewConsentForm,
  IQuestionViewList,
  IQuestionViewNrs,
  IQuestionViewRange,
  IQuestionViewTextArea,
  ISliderOptionsDto,
  IQuestionViewTextOrSkip,
  QuestionWithTranslation,
  TNRSDesctiptorKey,
  IQuestionViewPeriod,
  IQuestionViewTimePicker,
} from 'entities/Questions/questions.model';
import { communicationUI, IUIConnectedProps } from 'entities/UI/ui.communication';
import { EActionType } from 'entities/UI/ui.models';
import { ITranslationsConnectedProps, communicationTranslations } from 'entities/Translations/translations.communication';
import { NRSQuestion } from 'entities/Questions/QuestionTypes/NRSQuestion';

const { Option } = Select;
const { TextArea } = Input;

interface IComponentProps {
  assessment: IAssessmentDto;
  setQuestionsCount: (count: number | undefined) => void;
}

type AllProps = IAssessmentsConnectedProps &
  IQuestionsConnectedProps &
  IComponentProps &
  IUIConnectedProps &
  ITranslationsConnectedProps;

const QuestionsComponent: React.FC<AllProps> = (props) => {
  const {
    assessment,
    setQuestionsCount,
    questionsCollection,
    getQuestionsCollection,
    setChangedUiFormState,
    setUnchangedUiFormState,
    updateQuestionsModel,
    getAssessmentsCollection,
    uiFormState,
    setSelectedQuestionUiFormState,
    uiUnsavedItemsModal,
    deleteQuestionsModel,
    resetUiUnsavedItemsModal,
    showUiAddQuestionModal,
    setDepConflictUiFormState,
    unsetDepConflictUiFormState,
    setQuestionsRefs,
    getTranslationsCurrent,
    translationsCurrent,
    getAssessmentsTranslation,
    updateAssessmentsTranslation,
    assessmentsTranslation,
    setEmptyRequiredFieldsUiFormState,
  } = props;
  const { data } = questionsCollection;
  const selectedQuestionId = useMemo(() => uiFormState.data?.selectedQuestion, [uiFormState]);
  const [form] = Form.useForm();
  const [questionsCopy, setQuestionsCopy] = useState<IQuestionDto[]>();
  const [translatedQuestionsCopy, setTranslatedSelectedQuestionsCopy] = useState<IQuestionTranslation[]>();
  const [selectedQuestion, setSelectedQuestion] = useState<IQuestionDto | null>(
    questionsCopy?.find((question: IQuestionDto) => question.id === selectedQuestionId) || null
  );
  const [valuesCopy, setValuesCopy] = useState<IQuestionValue[]>(selectedQuestion?.values || []);
  const navigate = useNavigate();
  const selectedQuestionIndex = useMemo(
    () => questionsCopy?.findIndex((question: IQuestionDto) => question.id === selectedQuestionId) || -1,
    [questionsCopy, selectedQuestionId]
  );
  const questionsRef = useRef<HTMLDivElement[]>([]);
  const [dependencyConflicts, setDependencyConflict] = useState<string[]>([]);
  const [scoring, setScoring] = useState<boolean>(
    (selectedQuestion?.options as IQuestionOptionsDto)?.multiselect
      ? selectedQuestion?.values.some((value: IQuestionValue) => value.score) || false
      : true
  );
  const [selectedLanguage, setSelectedLanguage] = useState<ETranslations>(ETranslations.en);
  const isAnotherLang = selectedLanguage !== ETranslations.en;
  const [translatedSelectedQuestion, setTranslatedSelectedQuestion] = useState<IQuestionTranslation | null>(
    translatedQuestionsCopy?.find((question) => question.questionId === selectedQuestionId) || null
  );
  const [questionsWithTranslations, setQuestionsWithTranslations] = useState<QuestionWithTranslation[]>([]);

  const { data: translationsData } = translationsCurrent;

  const navigationControl = assessment.view.control;
  const isEmptyRequiredFields = !!form.getFieldsError().filter(({ errors }) => errors.length).length;

  const defaultOption = selectedQuestion?.values.find((value) => value.isDefault === true);
  const otherOption = selectedQuestion?.values.find((value) => value.isOther === true);
  const noneOfTheAboveOption = selectedQuestion?.values.find((value) => value.isNoneOfTheAbove === true);

  const translatedOtherOption = translatedSelectedQuestion?.values?.find((value) => value.questionValueId === otherOption?.id);
  const translatedNoneOfTheAboveOption = translatedSelectedQuestion?.values?.find(
    (value) => value.questionValueId === noneOfTheAboveOption?.id
  );
  const translatedDefaultOption = translatedSelectedQuestion?.values?.find(
    (value) => value.questionValueId === defaultOption?.id
  );

  useEffect(() => {
    getTranslationsCurrent();
    if (!data || data.id !== assessment.id) {
      getQuestionsCollection({ assessmentId: assessment.id });
    }
  }, []);

  useEffect(() => {
    if (data) {
      setQuestionsCopy(JSON.parse(JSON.stringify(data.questions)));
      setQuestionsRefs(questionsRef);
      setSelectedQuestionUiFormState({ selectedQuestion: data.questions[0]?.id || null });
      setQuestionsCount(data.questions.length);
    }
  }, [data]);

  useEffect(() => {
    if (questionsCopy && data?.questions && !isAnotherLang) {
      if (!isEqual(data?.questions, questionsCopy)) {
        if (!uiFormState.data?.isChanged) {
          setChangedUiFormState();
        }
      } else {
        if (uiFormState.data?.isChanged && !isAnotherLang) {
          setUnchangedUiFormState();
        }
      }
    } else if (translatedQuestionsCopy && assessmentsTranslation.data?.questions && isAnotherLang) {
      if (!isEqual(assessmentsTranslation.data?.questions, translatedQuestionsCopy)) {
        if (!uiFormState.data?.isChanged) {
          setChangedUiFormState();
        }
      } else {
        if (uiFormState.data?.isChanged) {
          setUnchangedUiFormState();
        }
      }
    }
  }, [questionsCopy, selectedLanguage, translatedQuestionsCopy]);

  useEffect(() => {
    if (!selectedQuestion) {
      setSelectedQuestion(questionsCopy?.find((question: IQuestionDto) => question.id === selectedQuestionId) || null);
    }
  }, [questionsCopy]);

  useEffect(() => {
    if (questionsCopy) {
      const sortedTranslations = questionsCopy?.map((i) => translatedQuestionsCopy?.find((j) => j.questionId === i.id));
      const newQuestions = questionsCopy.map((question: IQuestionDto, index) => {
        return { ...question, translations: sortedTranslations?.[index] };
      });

      setQuestionsWithTranslations(newQuestions);
    }
  }, [questionsCopy, translatedQuestionsCopy]);

  useEffect(() => {
    if (!translatedSelectedQuestion) {
      setTranslatedSelectedQuestion(
        translatedQuestionsCopy?.find((translatedQuestion) => translatedQuestion.questionId === selectedQuestionId) || null
      );
    }
  }, [translatedQuestionsCopy]);

  useEffect(() => {
    const newDependencyConflicts: string[] = [];

    questionsCopy?.forEach((question: IQuestionDto, index: number) => {
      const parents = question.parents;
      if (parents) {
        parents.forEach((parent) => {
          const parentIndex = questionsCopy.findIndex(
            (questionIter: IQuestionDto) => questionIter.id === parent.parentQuestionId
          );
          if (parent && parentIndex > index) {
            newDependencyConflicts.push(question.label);
          }
        });
      }
    });

    if (newDependencyConflicts.length) {
      if (!uiFormState.data?.dependencyConflicts) {
        setDepConflictUiFormState();
      }
    } else {
      if (uiFormState.data?.dependencyConflicts) {
        unsetDepConflictUiFormState();
      }
    }

    setDependencyConflict(newDependencyConflicts);
  }, [questionsCopy]);

  useEffect(() => {
    const question = questionsCopy?.find((questionIter: IQuestionDto) => questionIter.id === selectedQuestionId) || null;
    const translatedQuestion =
      translatedQuestionsCopy?.find((questionIter) => questionIter.questionId === selectedQuestionId) || null;

    if (question) {
      setSelectedQuestion(question);
      setTranslatedSelectedQuestion(translatedQuestion);
      setValuesCopy(question.values);

      form.setFieldsValue({
        [EQuestionForm.AddOther]: otherOption?.isOther,
        [EQuestionForm.Label]: isAnotherLang ? translatedQuestion?.label || '' : question.label,
        [EQuestionForm.AddOn]: isAnotherLang ? translatedQuestion?.view?.addOn || '' : question.view.addOn,
        [EQuestionForm.SubText]: isAnotherLang ? translatedQuestion?.view?.subText || '' : question.view.subText,
        [EQuestionForm.Type]: question.type,
        [EQuestionForm.Orientation]:
          question.type === EQuestionType.consentForm
            ? 'checkbox'
            : question.type === EQuestionType.textOrSkip
            ? (question?.view as IQuestionViewTextOrSkip)?.inputType
            : getAppearanceInitialValue(question?.view?.orientation, (question as any)?.view?.format),
        [EQuestionForm.Max]: (question.options as ISliderOptionsDto).max as number,
        [EQuestionForm.MaxLabel]: {
          max: {
            value: isAnotherLang
              ? translatedQuestion?.view?.max?.label || ''
              : (question?.view as IQuestionViewRange)?.max?.label,
          },
        },
        [EQuestionForm.Min]: (question.options as ISliderOptionsDto).min as number,
        [EQuestionForm.MinLabel]: {
          min: {
            value: isAnotherLang
              ? translatedQuestion?.view?.min?.label || ''
              : (question?.view as IQuestionViewRange)?.min?.label,
          },
        },
        [EQuestionForm.Multiselect]: (question.options as IQuestionOptionsDto).multiselect,
        [EQuestionForm.SkipOption]: question.skipOption,
        [EQuestionForm.Step]: (question.options as ISliderOptionsDto).step as number,
        [EQuestionForm.UseCustomMarkdown]: (question.options as IQuestionOptionsDto).useCustomMarkdown ? true : false,
        [EQuestionForm.DefaultPeriod]: defaultOption?.isDefault,
        [EQuestionForm.CustomMarkdown]: isAnotherLang
          ? translatedQuestion?.options?.customMarkdown || ''
          : (question.options as IQuestionOptionsDto).customMarkdown,
        [EQuestionForm.Key]: question.key,
        [EQuestionForm.ConsentType]: isAnotherLang
          ? translatedQuestion?.view?.checkboxTitle || ''
          : (question?.view as IQuestionViewConsentForm)?.checkboxTitle,
        [EQuestionForm.ListItemTitle]: isAnotherLang
          ? translatedQuestion?.view?.listItemTitle || ''
          : (question?.view as IQuestionViewList)?.listItemTitle,
        [EQuestionForm.ListItemPlaceholder]: isAnotherLang
          ? translatedQuestion?.view?.listItemPlaceholder || ''
          : (question?.view as IQuestionViewList)?.listItemPlaceholder,
        [EQuestionForm.OtherOptionTitle]: {
          [otherOption?.id as string]: {
            value: isAnotherLang ? translatedOtherOption?.value || '' : otherOption?.value || '',
          },
        },
        [EQuestionForm.SelectedDefaultPeriod]: isAnotherLang ? translatedDefaultOption?.value : defaultOption?.value,
        [EQuestionForm.ShowFaceImages]: (question?.view as IQuestionViewNrs)?.showFaceImages,
        [EQuestionForm.Palette]: (question?.view as IQuestionViewNrs)?.palette,
        [EQuestionForm.ShowDescription]: (question?.view as IQuestionViewNrs)?.showDescription,
        [EQuestionForm.NRSMinValueDescriptor]: isAnotherLang
          ? translatedQuestion?.view?.minValueDescriptor || ''
          : (question?.view as IQuestionViewNrs)?.minValueDescriptor,
        [EQuestionForm.NRSMaxValueDescriptor]: isAnotherLang
          ? translatedQuestion?.view?.maxValueDescriptor || ''
          : (question?.view as IQuestionViewNrs)?.maxValueDescriptor,
        [EQuestionForm.Placeholder]: isAnotherLang
          ? translatedQuestion?.view?.placeholder || ''
          : (question?.view as IQuestionViewTextArea)?.placeholder,
        [EQuestionForm.CheckboxText]: isAnotherLang
          ? translatedQuestion?.view?.checkboxText || ''
          : (question?.view as IQuestionViewTextOrSkip)?.checkboxText,
        [EQuestionForm.IsCurrentValueHidden]: !(question.view as IQuestionViewRange).isCurrentValueHidden,
        [EQuestionForm.ValuePlaceholder]: isAnotherLang
          ? translatedQuestion?.view?.valuePlaceholder
          : (question?.view as IQuestionViewPeriod)?.valuePlaceholder,
        [EQuestionForm.PeriodPlaceholder]: isAnotherLang
          ? translatedQuestion?.view?.periodPlaceholder
          : (question?.view as IQuestionViewPeriod)?.periodPlaceholder,
        [EQuestionForm.NoneOfTheAbove]: noneOfTheAboveOption?.isNoneOfTheAbove,
        [EQuestionForm.NoneOfTheAboveOptionTitle]: {
          [noneOfTheAboveOption?.id as string]: {
            value: isAnotherLang ? translatedNoneOfTheAboveOption?.value || '' : noneOfTheAboveOption?.value || '',
          },
        },
      });
    } else {
      setSelectedQuestion(null);
    }
  }, [selectedQuestionId, selectedLanguage, translatedQuestionsCopy]);

  useEffect(() => {
    setEmptyRequiredFieldsUiFormState({ isEmptyRequiredFields });
  }, [isEmptyRequiredFields]);

  useEffect(() => {
    if (selectedQuestion && questionsCopy) {
      setQuestionsCopy(
        questionsCopy.map((question: IQuestionDto) => {
          if (question.id === selectedQuestion.id) {
            question = selectedQuestion;
          }
          return question;
        })
      );
    }
    if (translatedSelectedQuestion && translatedQuestionsCopy) {
      setTranslatedSelectedQuestionsCopy(
        translatedQuestionsCopy.map((translatedQuestion) => {
          if (translatedQuestion.questionId === translatedSelectedQuestion.questionId) {
            translatedQuestion = translatedSelectedQuestion;
          }
          return translatedQuestion;
        })
      );
    }
  }, [selectedQuestion, translatedSelectedQuestion]);

  const selectQuestion = useCallback(
    (question: QuestionWithTranslation) => {
      if (selectedQuestion?.id !== question.id) {
        if (selectedQuestion && questionsCopy) {
          setQuestionsCopy(
            questionsCopy?.map((questionIter: IQuestionDto) => {
              if (questionIter.id === selectedQuestion.id) {
                questionIter = selectedQuestion;
              }
              return questionIter;
            })
          );
        }

        setSelectedQuestionUiFormState({ selectedQuestion: question.id });
      }
      if (translatedSelectedQuestion?.questionId !== question.id && translatedSelectedQuestion && translatedQuestionsCopy) {
        setTranslatedSelectedQuestionsCopy(
          translatedQuestionsCopy.map((translatedQuestionIter) => {
            if (translatedQuestionIter.questionId === translatedSelectedQuestion.questionId) {
              translatedQuestionIter = translatedSelectedQuestion;
            }
            return translatedQuestionIter;
          })
        );
      }
    },
    [selectedQuestion, valuesCopy, questionsCopy, data?.questions, translatedSelectedQuestion, translatedQuestionsCopy]
  );

  const removeOption = useCallback(
    (id: string) => {
      if (selectedQuestion) {
        const newValues = selectedQuestion.values.filter((value: IQuestionValue) => value.id !== id);
        const newValueOtherOption = newValues.find((value: IQuestionValue) => value.isOther === true);
        const newValueNoneOfTheAboveOption = newValues.find((value: IQuestionValue) => value.isNoneOfTheAbove === true);
        setSelectedQuestion({
          ...selectedQuestion,
          values: newValues,
          options: !newValueOtherOption
            ? { ...selectedQuestion.options, useOtherAnswer: undefined }
            : !newValueNoneOfTheAboveOption
            ? { ...selectedQuestion.options, useNoneOfTheAbove: undefined }
            : { ...selectedQuestion.options },
        });
        setValuesCopy(newValues);
      }
    },
    [selectedQuestion]
  );

  const removeRelation = useCallback(
    (id: string) => {
      if (selectedQuestion && selectedQuestion.parents) {
        const newRelations = selectedQuestion.parents.filter((parent: IQuestionRelationsDto) => id !== parent.id);
        setSelectedQuestion({ ...selectedQuestion, parents: newRelations });
      }
    },
    [selectedQuestion]
  );

  const addOption = useCallback(() => {
    if (selectedQuestion) {
      const newValues = selectedQuestion.values;
      newValues.push({
        id: `new_value ${uuid()}`,
        createdAt: Date.now().toString(),
        updatedAt: Date.now().toString(),
        value: '',
        question: selectedQuestion,
        score: undefined,
        order: newValues.length,
        isDefault: false,
        isOther: false,
        isNoneOfTheAbove: false,
        translations: [],
      });

      setSelectedQuestion({ ...selectedQuestion, values: newValues });
      setValuesCopy(newValues);
    }
  }, [selectedQuestion]);

  const addOtherOption = useCallback(() => {
    if (selectedQuestion) {
      const newValues = selectedQuestion.values;
      newValues.push({
        id: `new_value ${uuid()}`,
        createdAt: Date.now().toString(),
        updatedAt: Date.now().toString(),
        value: 'Other',
        question: selectedQuestion,
        score: undefined,
        order: newValues.length,
        isDefault: false,
        isOther: true,
        isNoneOfTheAbove: false,
        translations: [],
      });

      setSelectedQuestion({
        ...selectedQuestion,
        values: newValues,
        options: { ...selectedQuestion.options, useOtherAnswer: true },
      });
      setValuesCopy(newValues);
    }
  }, [selectedQuestion]);

  const addNoneOfTheAboveOption = useCallback(() => {
    if (selectedQuestion) {
      const newValues = selectedQuestion.values;
      newValues.push({
        id: `new_value ${uuid()}`,
        createdAt: Date.now().toString(),
        updatedAt: Date.now().toString(),
        value: 'None Of The Above',
        question: selectedQuestion,
        score: undefined,
        order: newValues.length,
        isDefault: false,
        isOther: false,
        isNoneOfTheAbove: true,
        translations: [],
      });

      setSelectedQuestion({
        ...selectedQuestion,
        values: newValues,
        options: { ...selectedQuestion.options, useNoneOfTheAbove: true },
      });
      setValuesCopy(newValues);
    }
  }, [selectedQuestion]);

  const addRelation = useCallback(() => {
    if (selectedQuestion) {
      const type =
        selectedQuestion.parents && selectedQuestion.parents?.length > 0
          ? selectedQuestion.parents[0].type === ERelationType.Visibility
            ? 'placeholder'
            : 'visibility'
          : 'visibility';
      const newRelation: IQuestionRelationsDto = {
        type: type as ERelationType,
        id: `new_value ${uuid()}`,
        questionId: selectedQuestion.id,
        createdAt: Date.now().toString(),
        updatedAt: Date.now().toString(),
        parentQuestionId: '',
        value:
          type === ERelationType.Visibility
            ? {
                value: '',
                condition: 'IS',
              }
            : undefined,
      };
      if (selectedQuestion.parents) {
        setSelectedQuestion({ ...selectedQuestion, parents: [...selectedQuestion.parents, newRelation] });
      } else {
        setSelectedQuestion({ ...selectedQuestion, parents: [newRelation] });
      }
    }
  }, [selectedQuestion]);

  const handleAddOther = useCallback(() => {
    if (!otherOption?.isOther) {
      addOtherOption();
    } else {
      removeOption(otherOption?.id);
    }
  }, [selectedQuestion]);

  const handleAddNoneOfTheAbove = useCallback(() => {
    if (!noneOfTheAboveOption?.isNoneOfTheAbove) {
      addNoneOfTheAboveOption();
    } else {
      removeOption(noneOfTheAboveOption?.id);
    }
  }, [selectedQuestion]);

  const updateTranslations = () => {
    if (translatedQuestionsCopy) {
      const assessmentTranslation: IAssessmentTranslation = {
        assessmentId: assessment.id,
        lang: selectedLanguage,
        questions: translatedQuestionsCopy,
      };
      updateAssessmentsTranslation({ assessmentId: assessment.id, body: assessmentTranslation }).then(() => {
        getAssessmentsCollection({})
          .then(() => setSelectedQuestionUiFormState({ selectedQuestion: null }))
          .then(() => {
            const storedNavigateTo = uiUnsavedItemsModal.data?.navigateTo;
            if (storedNavigateTo) {
              navigate(storedNavigateTo);
            }
          })
          .finally(() => {
            if (uiUnsavedItemsModal.data?.actionType === EActionType.AddQuestion) {
              showUiAddQuestionModal();
            }
            resetUiUnsavedItemsModal();
            getQuestionsCollection({ assessmentId: assessment.id });
            setUnchangedUiFormState();
          });
      });
    }
  };

  const updateQuestions = useCallback(async () => {
    if (data && questionsCopy) {
      const modifiedQuestions = findModifiedQuestions(data.questions, questionsCopy);
      const updateQuestionsRequests: Promise<void>[] = [];
      // eslint-disable-next-line prefer-const
      // eslint-disable-next-line
      for await (let [_, question] of modifiedQuestions.entries()) {
        let values;
        let parents;
        let view;
        let options;

        if (selectedQuestion) {
          if (question.id === selectedQuestion.id) {
            question = { ...selectedQuestion, order: question.order };
            values = selectedQuestion.values.map((value: IQuestionValue) => ({
              ...(!value.id.startsWith('new_value') && { id: value.id }),
              score: value.score,
              value: value.value,
              order: value.order,
              isDefault: value.isDefault,
              isOther: value.isOther,
              isNoneOfTheAbove: value.isNoneOfTheAbove,
            }));
            parents = selectedQuestion.parents?.map((parent) => ({
              ...(!parent.id.startsWith('new_value') && { id: parent.id }),
              type: parent.type,
              questionId: parent.questionId,
              parentQuestionId: parent.parentQuestionId,
              value: parent.value
                ? {
                    value: parent.value?.value,
                    condition: parent.value?.condition,
                  }
                : undefined,
            }));
            view = {
              ...selectedQuestion.view,
            };
            options = {
              ...selectedQuestion.options,
            };
          } else {
            values = question.values.map((value: IQuestionValue) => ({
              ...(!value.id.startsWith('new_value') && { id: value.id }),
              score: value.score,
              value: value.value,
              order: value.order,
              isDefault: value.isDefault,
              isOther: value.isOther,
              isNoneOfTheAbove: value.isNoneOfTheAbove,
            }));
            parents = question.parents?.map((parent) => ({
              ...(!parent.id.startsWith('new_value') && { id: parent.id }),
              type: parent.type,
              questionId: parent.questionId,
              parentQuestionId: parent.parentQuestionId,
              value: parent.value
                ? {
                    value: parent.value.value,
                    condition: parent.value.condition,
                  }
                : undefined,
            }));
            view = {
              ...question.view,
            };
            options = {
              ...question.options,
            };
          }
        }

        parents = parents ? parents : undefined;

        updateQuestionsRequests.push(
          updateQuestionsModel({
            questionId: question.id,
            body: {
              ...question,
              values,
              parents,
              view,
              options,
            },
          })
        );
      }

      Promise.all(updateQuestionsRequests).then((responses) => {
        if (responses.every((response) => response)) {
          if (
            uiUnsavedItemsModal.data?.actionType === EActionType.RemoveQuestion &&
            uiFormState.data &&
            questionsCollection.data
          ) {
            removeQuestion({
              uiFormState: uiFormState.data,
              questions: questionsCollection.data.questions,
              updateQuestionsModel,
              deleteQuestionsModel,
              setSelectedQuestionUiFormState,
              getAssessmentsCollection,
              assessmentId: questionsCollection.data.id,
              getQuestionsCollection,
            });
          } else {
            getAssessmentsCollection({})
              .then(() => setSelectedQuestionUiFormState({ selectedQuestion: null }))
              .then(() => {
                const storedNavigateTo = uiUnsavedItemsModal.data?.navigateTo;
                if (storedNavigateTo) {
                  navigate(storedNavigateTo);
                }
              })
              .finally(() => {
                if (uiUnsavedItemsModal.data?.actionType === EActionType.AddQuestion) {
                  showUiAddQuestionModal();
                }
                resetUiUnsavedItemsModal();
                getQuestionsCollection({ assessmentId: assessment.id });
              });
          }
        }
      });
    }
  }, [questionsCopy, navigate, selectedQuestion, uiUnsavedItemsModal, uiFormState.data, questionsCollection.data]);

  const getRelationTypeValue = useCallback(
    (index: number, parent: IQuestionRelationsDto) => {
      if (index === 0) {
        return parent.type;
      }
      return selectedQuestion?.parents?.[0]?.type === ERelationType.Visibility
        ? ERelationType.Placeholder
        : ERelationType.Visibility;
    },
    [selectedQuestion]
  );

  const handleNRSDescriptorItemChange = useCallback(
    (field: string, { key, value }: { key: TNRSDesctiptorKey; value: string }) => {
      if (selectedQuestion && !isAnotherLang) {
        const view = selectedQuestion.view as IQuestionViewNrs;

        const updatedView = {
          ...view,
          description: {
            ...view.description,
            [key]: { ...view.description?.[key], [field]: value },
          },
        };

        if (!value) {
          // @ts-ignore
          delete updatedView.description[key]?.[field];
        }

        if (!Object.keys(updatedView.description).length) {
          // @ts-ignore
          delete updatedView.description;
        }

        setSelectedQuestion({
          ...selectedQuestion,
          view: updatedView,
        });
      } else if (translatedSelectedQuestion) {
        const view = translatedSelectedQuestion?.view || {};
        const updatedView = {
          ...view,
          description: {
            ...view.description,
            [key]: { ...view.description?.[key], [field]: value },
          },
        };

        if (!value) {
          // @ts-ignore
          delete updatedView.description[key]?.[field];
        }

        if (!Object.keys(updatedView.description).length) {
          // @ts-ignore
          delete updatedView.description;
        }
        setTranslatedSelectedQuestion({
          ...translatedSelectedQuestion,
          // @ts-ignore
          view: updatedView,
        });
      }
    },
    [selectedQuestion, translatedSelectedQuestion, isAnotherLang]
  );

  const handleNRSMinMaxDescriptorsChange = useCallback(
    (key: string, value: string) => {
      if (selectedQuestion && !isAnotherLang) {
        const view = selectedQuestion?.view || {};
        const updatedView = {
          ...view,
          [key]: value,
        };

        setSelectedQuestion({
          ...selectedQuestion,
          view: updatedView,
        });
      } else if (translatedSelectedQuestion) {
        const view = translatedSelectedQuestion?.view || {};
        const updatedView = {
          ...view,
          [key]: value,
        };

        setTranslatedSelectedQuestion({ ...translatedSelectedQuestion, view: updatedView });
      }
    },
    [selectedQuestion, translatedSelectedQuestion, isAnotherLang]
  );

  const handleValuesChange = useCallback(
    (changedValue: { [key: string]: string | boolean | Object }) => {
      const key = Object.keys(changedValue)[0];
      const value = Object.values(changedValue)[0];

      switch (key) {
        case EQuestionForm.Label:
          return handleLabelChange(
            selectedQuestion,
            translatedSelectedQuestion,
            setSelectedQuestion,
            setTranslatedSelectedQuestion,
            isAnotherLang,
            value as string
          );
        case EQuestionForm.Key:
          return handleKeyChange(selectedQuestion, setSelectedQuestion, value as string);
        case EQuestionForm.SubText:
          return handleSubTextChange(
            selectedQuestion,
            translatedSelectedQuestion,
            setSelectedQuestion,
            setTranslatedSelectedQuestion,
            isAnotherLang,
            value as string
          );
        case EQuestionForm.Type:
          return handleTypeChange(
            selectedQuestion,
            setSelectedQuestion,
            setValuesCopy,
            value as string,
            valuesCopy,
            questionsCopy,
            form
          );
        case EQuestionForm.Options:
          return handleOptionChange(selectedQuestion, setSelectedQuestion, isAnotherLang, {
            id: Object.keys(value)[0],
            value: Object.values(value)[0],
          });
        case EQuestionForm.TranslatedOptions:
          return handleTranslatedOptionChange(translatedSelectedQuestion, setTranslatedSelectedQuestion, {
            id: Object.keys(value)[0],
            value: Object.values(value)[0],
          });
        case EQuestionForm.Multiselect:
          return (
            selectedQuestion &&
            setSelectedQuestion({
              ...selectedQuestion,
              options: {
                ...selectedQuestion.options,
                multiselect: (selectedQuestion?.options as IQuestionOptionsDto).multiselect
                  ? !(selectedQuestion.options as IQuestionOptionsDto).multiselect
                  : true,
              },
            })
          );
        case EQuestionForm.RelationType:
          return handleRelationTypeChange(
            selectedQuestion,
            setSelectedQuestion,
            {
              id: Object.keys(value)[0],
              value: Object.values(value)[0],
            },
            form
          );
        case EQuestionForm.RelationParent:
          return handleRelationParentChange(selectedQuestion, setSelectedQuestion, {
            id: Object.keys(value)[0],
            value: Object.values(value)[0],
          });
        case EQuestionForm.RelationCondition:
          return handleRelationConditionChange(selectedQuestion, setSelectedQuestion, {
            id: Object.keys(value)[0],
            value: Object.values(value)[0],
          });
        case EQuestionForm.RelationValue:
          return handleRelationValueChange(selectedQuestion, setSelectedQuestion, {
            id: Object.keys(value)[0],
            value: Object.values(value)[0],
          });
        case EQuestionForm.Max:
        case EQuestionForm.Min:
        case EQuestionForm.Step:
          return (
            selectedQuestion &&
            setSelectedQuestion({
              ...selectedQuestion,
              options: { ...selectedQuestion.options, [key]: parseInt(value as string) },
            })
          );
        case EQuestionForm.Orientation:
          const singleChoiceView = (value as string).split(' ');
          let singleChoiceViewFormat = {};
          if (singleChoiceView[1]) {
            singleChoiceViewFormat = {
              format: singleChoiceView[1],
            };
          }
          return (
            selectedQuestion &&
            setSelectedQuestion({
              ...selectedQuestion,
              view: {
                ...selectedQuestion.view,
                orientation:
                  singleChoiceView[0] === EQuestionOrientation.Horizontal
                    ? EQuestionOrientation.Horizontal
                    : EQuestionOrientation.Vertical,
                ...singleChoiceViewFormat,
                // @ts-ignore
                inputType: selectedQuestion?.type === EQuestionType.textOrSkip ? singleChoiceView[0] : undefined,
              },
            })
          );
        case EQuestionForm.ConsentType:
          return handleConsentTypeChange(
            selectedQuestion,
            translatedSelectedQuestion,
            setSelectedQuestion,
            setTranslatedSelectedQuestion,
            isAnotherLang,
            value as string
          );
        case EQuestionForm.AddOn:
          return handleAddOnChange(
            selectedQuestion,
            translatedSelectedQuestion,
            setSelectedQuestion,
            setTranslatedSelectedQuestion,
            isAnotherLang,
            value as string
          );
        case EQuestionForm.SkipOption:
          return selectedQuestion && setSelectedQuestion({ ...selectedQuestion, skipOption: value as ESkipOption });
        case EQuestionForm.Scoring: {
          setScoring(!scoring);
          return (
            selectedQuestion &&
            setSelectedQuestion({
              ...selectedQuestion,
              values: selectedQuestion?.values.map((entry: IQuestionValue) => {
                entry.score = undefined;
                return entry;
              }),
            })
          );
        }
        case EQuestionForm.MaxLabel:
        case EQuestionForm.MinLabel:
          return handleSliderDescriptionChange(
            selectedQuestion,
            translatedSelectedQuestion,
            setSelectedQuestion,
            setTranslatedSelectedQuestion,
            isAnotherLang,
            {
              key: Object.keys(value)[0],
              value: Object.values(value)[0],
            }
          );
        case EQuestionForm.ShowFaceImages:
        case EQuestionForm.ShowDescription:
        case EQuestionForm.Palette:
          return handleNRSOptionsChange(selectedQuestion, setSelectedQuestion, {
            key,
            value: value as string | boolean,
          });
        case EQuestionForm.AddOther:
          return handleAddOther();
        case EQuestionForm.NoneOfTheAbove: {
          return handleAddNoneOfTheAbove();
        }
        case EQuestionForm.OtherOptionTitle:
        case EQuestionForm.NoneOfTheAboveOptionTitle:
          return handleAuxiliaryOptionValueChange(
            selectedQuestion,
            translatedSelectedQuestion,
            setSelectedQuestion,
            setTranslatedSelectedQuestion,
            isAnotherLang,
            {
              id: Object.keys(value)[0],
              value: Object.values(value)[0],
            }
          );
        case EQuestionForm.DefaultPeriod:
          return handleAddOtherPeriod(
            selectedQuestion,
            setSelectedQuestion,
            {
              id: Object.keys(value)[0],
              value: Object.values(value)[0],
            },
            form
          );
        case EQuestionForm.SelectedDefaultPeriod:
          return handleDefaultSelectedChange(selectedQuestion, setSelectedQuestion, value as string);
        case EQuestionForm.UseCustomMarkdown:
          return (
            selectedQuestion &&
            setSelectedQuestion({
              ...selectedQuestion,
              options: {
                ...selectedQuestion.options,
                useCustomMarkdown: value as boolean | undefined,
                customMarkdown:
                  (value as boolean) === false ? undefined : (selectedQuestion.options as IQuestionOptionsDto).customMarkdown,
              },
            })
          );
        case EQuestionForm.CustomMarkdown:
          return handleCustomMarkdownChange(
            selectedQuestion,
            translatedSelectedQuestion,
            setSelectedQuestion,
            setTranslatedSelectedQuestion,
            isAnotherLang,
            value as string
          );
        case EQuestionForm.ListItemTitle:
          return handleListItemTitleChange(
            selectedQuestion,
            translatedSelectedQuestion,
            setSelectedQuestion,
            setTranslatedSelectedQuestion,
            isAnotherLang,
            value as string
          );
        case EQuestionForm.ListItemPlaceholder:
          return handleListItemPlaceholderChange(
            selectedQuestion,
            translatedSelectedQuestion,
            setSelectedQuestion,
            setTranslatedSelectedQuestion,
            isAnotherLang,
            value as string
          );
        case EQuestionForm.Placeholder:
          return handlePlaceholderChange(
            selectedQuestion,
            translatedSelectedQuestion,
            setSelectedQuestion,
            setTranslatedSelectedQuestion,
            isAnotherLang,
            value as string
          );
        case EQuestionForm.NRSDescriptorItemClassification:
        case EQuestionForm.NRSDescriptorItemDescription:
          const nestedKeyValuePair = Object.entries(value);

          return handleNRSDescriptorItemChange(key, {
            // @ts-ignore
            key: nestedKeyValuePair[0][0],
            value: nestedKeyValuePair[0][1],
          });
        case EQuestionForm.NRSMinValueDescriptor:
        case EQuestionForm.NRSMaxValueDescriptor:
          return handleNRSMinMaxDescriptorsChange(key, value as string);
        case EQuestionForm.CheckboxText:
          return handleCheckboxTextChange(
            selectedQuestion,
            translatedSelectedQuestion,
            setSelectedQuestion,
            setTranslatedSelectedQuestion,
            isAnotherLang,
            value as string
          );
        case EQuestionForm.IsCurrentValueHidden:
          return (
            selectedQuestion &&
            setSelectedQuestion({
              ...selectedQuestion,
              view: { ...selectedQuestion.view, isCurrentValueHidden: !value as boolean },
            })
          );
        case EQuestionForm.ValuePlaceholder:
        case EQuestionForm.PeriodPlaceholder: {
          return handlePeriodPlaceholderChange(
            selectedQuestion,
            translatedSelectedQuestion,
            setSelectedQuestion,
            setTranslatedSelectedQuestion,
            isAnotherLang,
            key,
            value as string
          );
        }
      }
    },
    [
      handleLabelChange,
      handleTypeChange,
      handleOptionChange,
      handleRelationParentChange,
      handleRelationConditionChange,
      handleRelationTypeChange,
      handleRelationValueChange,
      selectedQuestion,
      setSelectedQuestion,
      scoring,
      handleAddOther,
      handleAddNoneOfTheAbove,
      handleSliderDescriptionChange,
      handleAuxiliaryOptionValueChange,
      handleDefaultSelectedChange,
      handleAddOtherPeriod,
      handleListItemTitleChange,
      handleListItemPlaceholderChange,
      handleAddOnChange,
      handleSubTextChange,
      handleCustomMarkdownChange,
      handlePlaceholderChange,
      handleConsentTypeChange,
      handleTranslatedOptionChange,
      translatedSelectedQuestion,
      isAnotherLang,
      handleNRSDescriptorItemChange,
      handleNRSMinMaxDescriptorsChange,
      handleCheckboxTextChange,
      handlePeriodPlaceholderChange,
    ]
  );

  const handleTextareaChange = useCallback(
    ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
      if (selectedQuestion) {
        if (!selectedQuestion.values[0]) {
          const newValues = [
            {
              id: `new_value ${uuid()}`,
              createdAt: Date.now().toString(),
              updatedAt: Date.now().toString(),
              value: target.value,
              question: selectedQuestion,
              score: undefined,
              order: 0,
              isDefault: false,
              isOther: false,
              isNoneOfTheAbove: false,
              translations: [],
            },
          ];
          setSelectedQuestion({ ...selectedQuestion, values: newValues });
          setValuesCopy(newValues);
        } else {
          const newValues = [{ ...selectedQuestion.values[0], value: target.value }];
          setSelectedQuestion({ ...selectedQuestion, values: newValues });
          setValuesCopy(newValues);
        }
      }
    },
    [selectedQuestion]
  );

  const reorderQuestions = useCallback(
    (index: number, type: EReorderType) => {
      if (questionsCopy) {
        const questions = questionsCopy.slice();
        const element = questions.splice(index, 1)[0];
        let newIndex = 0;
        if (type === EReorderType.Up) {
          newIndex = index - 1;
        } else {
          newIndex = index + 1;
        }
        questions.splice(newIndex, 0, element);
        setTimeout(() => questionsRef.current[newIndex].scrollIntoView({ behavior: 'smooth', block: 'center' }), 0);

        setQuestionsCopy(questions);
      }
    },
    [questionsCopy, questionsRef]
  );

  const reorderOptions = (list: IQuestionValue[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((item, index) => ({ ...item, order: index }));
  };

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      if (selectedQuestion) {
        const newValues = reorderOptions(selectedQuestion?.values, result.source.index, result.destination.index);

        setSelectedQuestion({ ...selectedQuestion, values: newValues });
        setValuesCopy(newValues);
      }
    },
    [selectedQuestion]
  );

  const getAppearance = (type: EQuestionType): JSX.Element => {
    switch (type) {
      case EQuestionType.singleChoice:
        return (
          <>
            <Option value={[EQuestionOrientation.Horizontal, EQuestionFormat.Radio].join(' ')}>Radio - horizontal</Option>
            <Option value={[EQuestionOrientation.Vertical, EQuestionFormat.Radio].join(' ')}>Radio - vertical</Option>
            <Option value={[EQuestionOrientation.Horizontal, EQuestionFormat.Button].join(' ')}>Buttons - horizontal</Option>
            <Option value={[EQuestionOrientation.Vertical, EQuestionFormat.Button].join(' ')}>Buttons - vertical</Option>
          </>
        );
      case EQuestionType.consentForm:
        return <Option value={EQuestionFormat.Checkbox}>Checkbox</Option>;
      case EQuestionType.textOrSkip:
        return (
          <>
            <Option value={ETextOrSkipQuestionInputType.Textarea}>text area</Option>
            <Option value={ETextOrSkipQuestionInputType.Input}>text box</Option>
          </>
        );
      default:
        return (
          <>
            <Option value={EQuestionOrientation.Vertical}>Vertical</Option>
            <Option value={EQuestionOrientation.Horizontal}>Horizontal</Option>
          </>
        );
    }
  };

  const getAppearanceInitialValue = (orientation?: string, format?: string): string => {
    return [orientation, format].filter((value) => value).join(' ');
  };

  const handleChangeLanguage = (value: ETranslations) => {
    setSelectedLanguage(value);

    if (value !== ETranslations.en) {
      setUnchangedUiFormState();

      getAssessmentsTranslation({ lang: value, assessmentId: assessment.id }).then((translatedAssessment) => {
        setTranslatedSelectedQuestionsCopy(JSON.parse(JSON.stringify(translatedAssessment.questions)));
      });
    }
  };

  const sortedTranslationsArray = React.useMemo(() => {
    const arr: IQuestionTranslationValue[] = [];
    if (selectedQuestion) {
      selectedQuestion.values.forEach((item) => {
        translatedSelectedQuestion?.values?.forEach((el) => {
          if (item.id === el.questionValueId) {
            arr.push(el);
          }
        });
      });

      return arr;
    }

    return undefined;
  }, [selectedQuestion?.values, translatedSelectedQuestion?.values]);

  return (
    <div className="questions__form">
      <div className="questions__form_common">
        <div className="questions__form_container">
          {(questionsWithTranslations &&
            questionsWithTranslations.length > 0 &&
            questionsWithTranslations.map((question, index: number) => (
              <div
                className="questions__form_item"
                key={question.id}
                ref={(el: HTMLDivElement) => (questionsRef.current[index] = el)}
              >
                <LeftPanel order={index} question={question} isAnotherLang={isAnotherLang} />
                <div
                  className={setSelectedQuestionStyles(selectedQuestion, question.id)}
                  onClick={() => selectQuestion(question)}
                >
                  {!(question.options as IQuestionOptionsDto).useCustomMarkdown &&
                    (isAnotherLang && question.translations?.view?.addOn
                      ? question.translations?.view?.addOn
                      : question.view.addOn) && (
                      <p className="questions__form_addOn">
                        {isAnotherLang && question.translations?.view?.addOn
                          ? question.translations?.view?.addOn
                          : question.view.addOn}
                      </p>
                    )}
                  {!(question.options as IQuestionOptionsDto).useCustomMarkdown &&
                    !(question.options as IQuestionOptionsDto).customMarkdown && (
                      <p
                        className={
                          !(question.options as IQuestionOptionsDto).useCustomMarkdown &&
                          (isAnotherLang && question.translations?.view?.subText
                            ? question.translations?.view?.subText
                            : question.view.subText)
                            ? 'questions__form_title--boxShadowNone'
                            : 'questions__form_title'
                        }
                      >
                        {isAnotherLang && question.translations?.label ? question.translations?.label : question.label}
                      </p>
                    )}
                  {!(question.options as IQuestionOptionsDto).useCustomMarkdown &&
                    (isAnotherLang && question.translations?.view?.subText
                      ? question.translations?.view?.subText
                      : question.view.subText) && (
                      <div className="questions__form_subText">
                        {parseValueToHtml(
                          isAnotherLang && question.translations?.view?.subText
                            ? question.translations.view.subText
                            : (question.view.subText as string)
                        )}
                      </div>
                    )}
                  {(question.options as IQuestionOptionsDto).useCustomMarkdown &&
                    (isAnotherLang && question.translations?.options?.customMarkdown
                      ? question.translations?.options?.customMarkdown
                      : (question.options as IQuestionOptionsDto).customMarkdown) && (
                      <div className="questions__form_subText">
                        {parseValueToHtml(
                          isAnotherLang && question.translations?.options?.customMarkdown
                            ? question.translations.options.customMarkdown
                            : ((question.options as IQuestionOptionsDto).customMarkdown as string)
                        )}
                      </div>
                    )}
                  {renderQuestionValues(question, handleTextareaChange, isAnotherLang)}
                </div>
                {questionsWithTranslations.length > 1 && question.id === selectedQuestionId && (
                  <div className="questions__form_order__buttons">
                    <Button
                      className={`${index === 0 && 'questions__form_order__buttons--hidden'}`}
                      onClick={() => reorderQuestions(index, EReorderType.Up)}
                      disabled={isAnotherLang}
                    >
                      {'▲'}
                    </Button>
                    <Button
                      className={`${index === questionsWithTranslations.length - 1 && 'questions__form_order__buttons--hidden'}`}
                      onClick={() => reorderQuestions(index, EReorderType.Down)}
                      disabled={isAnotherLang}
                    >
                      {'▼'}
                    </Button>
                  </div>
                )}
              </div>
            ))) || <Empty />}
        </div>
      </div>
      {selectedQuestion && (
        <div className="questions__form_settings">
          <div className="questions__form_settings_wrapper">
            <div className="questions__form-content_container">
              <p className="questions__form_title questions__form_settings-title">Question settings</p>
              {translationsData && (
                <Select
                  className="questions__form-content__translations"
                  value={selectedLanguage}
                  onSelect={handleChangeLanguage}
                >
                  {translationsData.translations.map((translation) => (
                    <Option key={translation} value={translation}>
                      {ETranslationsTitle[translation]}
                    </Option>
                  ))}
                </Select>
              )}
            </div>
            <Form
              id="form"
              key={selectedQuestion.id}
              className="questions__form_settings-form questions__form_container"
              onFinish={isAnotherLang ? updateTranslations : updateQuestions}
              onValuesChange={handleValuesChange}
              labelWrap
              form={form}
              layout="vertical"
            >
              {!isAnotherLang && (
                <Form.Item label="Type" name={EQuestionForm.Type} initialValue={selectedQuestion.type}>
                  <Select value={selectedQuestion.type}>
                    {Object.values(EQuestionType).map((type) => (
                      <Option key={type} value={type}>
                        {EQuestionTypeTitle[type]}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item label="Question" name={EQuestionForm.Label} initialValue={selectedQuestion.label}>
                <Input value={selectedQuestion.label} placeholder={isAnotherLang ? selectedQuestion.label : ''} />
              </Form.Item>
              {!isAnotherLang && (
                <Form.Item label="Key" name={EQuestionForm.Key} initialValue={selectedQuestion.key}>
                  <Input value={selectedQuestion.key} />
                </Form.Item>
              )}
              <Form.Item
                className="t-align-c"
                name={EQuestionForm.UseCustomMarkdown}
                initialValue={(selectedQuestion.options as IQuestionOptionsDto).useCustomMarkdown}
              >
                <Radio.Group>
                  <Radio.Button
                    disabled={isAnotherLang}
                    defaultChecked={(selectedQuestion.options as IQuestionOptionsDto).useCustomMarkdown ? false : true}
                    value={false}
                  >
                    Standard Layout
                  </Radio.Button>
                  <Radio.Button
                    disabled={isAnotherLang}
                    defaultChecked={(selectedQuestion.options as IQuestionOptionsDto).useCustomMarkdown}
                    value={true}
                  >
                    Custom Layout
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              {(selectedQuestion.options as IQuestionOptionsDto).useCustomMarkdown ? (
                <Form.Item
                  name={EQuestionForm.CustomMarkdown}
                  initialValue={(selectedQuestion.options as IQuestionOptionsDto).customMarkdown}
                >
                  <TextArea
                    rows={6}
                    value={(selectedQuestion.options as IQuestionOptionsDto).customMarkdown}
                    placeholder={isAnotherLang ? (selectedQuestion.options as IQuestionOptionsDto).customMarkdown : ''}
                  />
                </Form.Item>
              ) : (
                <>
                  <Form.Item label="Header" name={EQuestionForm.AddOn} initialValue={selectedQuestion.view.addOn}>
                    <Input value={selectedQuestion.view.addOn} placeholder={isAnotherLang ? selectedQuestion.view.addOn : ''} />
                  </Form.Item>
                  <Form.Item label="Sub-text" name={EQuestionForm.SubText} initialValue={selectedQuestion.view.subText}>
                    <TextArea
                      rows={2}
                      value={selectedQuestion.view.subText}
                      placeholder={isAnotherLang ? selectedQuestion.view.subText : ''}
                    />
                  </Form.Item>
                </>
              )}
              {!isAnotherLang && navigationControl && navigationControl !== ENavigationType.None && (
                <Form.Item name={EQuestionForm.SkipOption} label="Can skip" initialValue={selectedQuestion.skipOption}>
                  <Select value={selectedQuestion.skipOption}>
                    <Option value={ESkipOption.None}>None</Option>
                    <Option value={ESkipOption.WithConfirmation}>With confirmation</Option>
                    <Option value={ESkipOption.WithoutConfirmation}>Without confirmation</Option>
                  </Select>
                </Form.Item>
              )}
              <div className="questions__form_settings-subTitle">Response options settings</div>
              {!isAnotherLang && checkPossibility(selectedQuestion, EQuestionPossibleType.UniqueAppearance) && (
                <Form.Item
                  name={EQuestionForm.Orientation}
                  label="Appearance"
                  initialValue={getAppearanceInitialValue(
                    selectedQuestion.view?.orientation,
                    (selectedQuestion as any)?.view?.format
                  )}
                >
                  <Select
                    value={getAppearanceInitialValue(selectedQuestion.view?.orientation, (selectedQuestion as any)?.view?.format)}
                  >
                    {getAppearance(selectedQuestion.type as EQuestionType)}
                  </Select>
                </Form.Item>
              )}
              {selectedQuestion.type === EQuestionType.period && (
                <>
                  <Form.Item name={EQuestionForm.ValuePlaceholder} label="Period value placeholder">
                    <Input
                      value={(selectedQuestion.view as IQuestionViewPeriod).valuePlaceholder}
                      placeholder={isAnotherLang ? (selectedQuestion.view as IQuestionViewPeriod).valuePlaceholder : ''}
                    />
                  </Form.Item>
                  <Form.Item name={EQuestionForm.PeriodPlaceholder} label="Period type placeholder">
                    <Input
                      value={(selectedQuestion.view as IQuestionViewPeriod).periodPlaceholder}
                      placeholder={isAnotherLang ? (selectedQuestion.view as IQuestionViewPeriod).periodPlaceholder : ''}
                    />
                  </Form.Item>
                </>
              )}
              {selectedQuestion.type === EQuestionType.slider ? (
                <div className="questions__form_settings-slider mb-6">
                  {!isAnotherLang && (
                    <Form.Item
                      name={EQuestionForm.Max}
                      label="Upper bound"
                      className="questions__form_settings-slider--row"
                      initialValue={(selectedQuestion.options as ISliderOptionsDto).max as number}
                    >
                      <Input
                        type="number"
                        value={(selectedQuestion.options as ISliderOptionsDto).max as number}
                        placeholder="Upper bound"
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    name={[EQuestionForm.MaxLabel, 'max', 'value']}
                    label="Upper bound description"
                    className="questions__form_settings-slider--row"
                    initialValue={(selectedQuestion.view as IQuestionViewRange)?.max?.label}
                  >
                    <Input
                      value={(selectedQuestion.view as IQuestionViewRange)?.max?.label}
                      placeholder={isAnotherLang ? (selectedQuestion.view as IQuestionViewRange)?.max?.label : 'description'}
                    />
                  </Form.Item>
                  {!isAnotherLang && (
                    <Form.Item
                      name={EQuestionForm.Min}
                      label="Lower bound"
                      className="questions__form_settings-slider--row"
                      initialValue={(selectedQuestion.options as ISliderOptionsDto).min as number}
                    >
                      <Input
                        type="number"
                        value={(selectedQuestion.options as ISliderOptionsDto).min as number}
                        placeholder="Lower bound"
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    name={[EQuestionForm.MinLabel, 'min', 'value']}
                    label="Lower bound description"
                    className="questions__form_settings-slider--row"
                    initialValue={(selectedQuestion.view as IQuestionViewRange)?.min?.label}
                  >
                    <Input
                      value={(selectedQuestion.view as IQuestionViewRange)?.min?.label}
                      placeholder={isAnotherLang ? (selectedQuestion.view as IQuestionViewRange)?.min?.label : 'description'}
                    />
                  </Form.Item>
                  {!isAnotherLang && (
                    <Form.Item
                      name={EQuestionForm.Step}
                      label="Slider step"
                      className="questions__form_settings-slider--row"
                      initialValue={(selectedQuestion.options as ISliderOptionsDto).step as number}
                    >
                      <Input
                        type="number"
                        value={(selectedQuestion.options as ISliderOptionsDto).step as number}
                        placeholder="Slider step"
                        min={1}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    name={[EQuestionForm.IsCurrentValueHidden]}
                    label="Values visibility"
                    className="questions__form_settings-slider--row"
                    initialValue={!(selectedQuestion.view as IQuestionViewRange)?.isCurrentValueHidden}
                    valuePropName="checked"
                  >
                    <Switch checkedChildren="Visible" unCheckedChildren="Hidden" />
                  </Form.Item>
                </div>
              ) : (
                checkPossibility(selectedQuestion, EQuestionPossibleType.Options) && (
                  <>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            <Form.Item className="questions__form_settings-title--block" label="Options">
                              {isAnotherLang
                                ? sortedTranslationsArray?.map((value) => {
                                    if (
                                      (value.questionValueId === otherOption?.id ||
                                        value.questionValueId === noneOfTheAboveOption?.id) &&
                                      selectedQuestion.type !== EQuestionType.period
                                    ) {
                                      return null;
                                    } else {
                                      return (
                                        <div key={value.questionValueId} className="questions__form_settings-options">
                                          <Form.Item
                                            name={['translatedOptions', value.questionValueId]}
                                            className="questions__form_settings-options"
                                            initialValue={value.value || ''}
                                          >
                                            <Input
                                              value={value.value || ''}
                                              placeholder={
                                                selectedQuestion.values.find(
                                                  (selectedQuestionValue) => selectedQuestionValue.id === value.questionValueId
                                                )?.value || 'Option'
                                              }
                                            />
                                          </Form.Item>
                                        </div>
                                      );
                                    }
                                  })
                                : selectedQuestion.values?.map((value: IQuestionValue, index) => {
                                    if (
                                      (value.isOther === true || value.isNoneOfTheAbove === true) &&
                                      selectedQuestion.type !== EQuestionType.period
                                    ) {
                                      return null;
                                    } else {
                                      return (
                                        <Draggable key={value.id} draggableId={value.id} index={index}>
                                          {(provid) => (
                                            <div
                                              className="questions__form_settings-options"
                                              ref={provid.innerRef}
                                              {...provid.draggableProps}
                                              {...provid.dragHandleProps}
                                            >
                                              <Form.Item
                                                name={['options', value.id, 'value']}
                                                className="questions__form_settings-options"
                                                initialValue={value.value}
                                              >
                                                <Input value={value.value} placeholder="Option" />
                                              </Form.Item>
                                              {scoring && selectedQuestion.type !== EQuestionType.period && (
                                                <Form.Item
                                                  name={['options', value.id, 'score']}
                                                  className="questions__form_settings-options"
                                                  initialValue={value.score}
                                                >
                                                  <Input type="number" value={value.score || ''} placeholder="Score" />
                                                </Form.Item>
                                              )}
                                              <Button onClick={() => removeOption(value.id)}>X</Button>
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    }
                                  })}
                              {provided.placeholder}
                            </Form.Item>
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <div className="questions__form_settings-btn-wrapper">
                      <Button disabled={isAnotherLang} className="questions__form_settings-btn" onClick={addOption}>
                        Add new option
                      </Button>
                    </div>
                  </>
                )
              )}
              {checkPossibility(selectedQuestion, EQuestionPossibleType.OtherOption) && (
                <>
                  <Form.Item name={EQuestionForm.AddOther}>
                    <Checkbox disabled={isAnotherLang} checked={otherOption?.isOther}>
                      Add Other
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    style={{ opacity: otherOption?.isOther ? 1 : 0.5 }}
                    label="Other option title"
                    name={[EQuestionForm.OtherOptionTitle, otherOption ? otherOption.id : '', 'value']}
                    initialValue={isAnotherLang ? translatedOtherOption?.value || '' : otherOption?.value || ''}
                  >
                    <Input
                      placeholder="Other"
                      value={isAnotherLang ? translatedOtherOption?.value || '' : otherOption?.value || ''}
                      disabled={!otherOption?.isOther}
                    />
                  </Form.Item>
                </>
              )}
              {checkPossibility(selectedQuestion, EQuestionPossibleType.NoneOfTheAboveOption) && (
                <>
                  <Form.Item name={EQuestionForm.NoneOfTheAbove}>
                    <Checkbox disabled={isAnotherLang} checked={noneOfTheAboveOption?.isNoneOfTheAbove}>
                      Add None Of The Above
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    style={{ opacity: noneOfTheAboveOption?.isNoneOfTheAbove ? 1 : 0.5 }}
                    label="None Of The Above option title"
                    name={[EQuestionForm.NoneOfTheAboveOptionTitle, noneOfTheAboveOption ? noneOfTheAboveOption.id : '', 'value']}
                    initialValue={isAnotherLang ? translatedOtherOption?.value || '' : noneOfTheAboveOption?.value || ''}
                  >
                    <Input
                      placeholder="None Of The Above"
                      value={isAnotherLang ? translatedNoneOfTheAboveOption?.value || '' : noneOfTheAboveOption?.value || ''}
                      disabled={!noneOfTheAboveOption?.isNoneOfTheAbove}
                    />
                  </Form.Item>
                </>
              )}
              {selectedQuestion.type === EQuestionType.list && (
                <>
                  <Form.Item
                    label="List item title"
                    name={EQuestionForm.ListItemTitle}
                    initialValue={(selectedQuestion.view as IQuestionViewList).listItemTitle}
                  >
                    <Input
                      value={(selectedQuestion.view as IQuestionViewList).listItemTitle}
                      placeholder={isAnotherLang ? (selectedQuestion.view as IQuestionViewList).listItemTitle : ''}
                    />
                  </Form.Item>
                  <Form.Item
                    label="List item placeholder"
                    name={EQuestionForm.ListItemPlaceholder}
                    initialValue={(selectedQuestion.view as IQuestionViewList).listItemPlaceholder}
                  >
                    <Input
                      value={(selectedQuestion.view as IQuestionViewList).listItemPlaceholder}
                      placeholder={isAnotherLang ? (selectedQuestion.view as IQuestionViewList).listItemPlaceholder : ''}
                    />
                  </Form.Item>
                </>
              )}
              {selectedQuestion.type === EQuestionType.consentForm && (
                <Form.Item
                  label="Checkbox text"
                  name={EQuestionForm.ConsentType}
                  initialValue={(selectedQuestion.view as IQuestionViewConsentForm).checkboxTitle}
                >
                  <Input
                    value={(selectedQuestion.view as IQuestionViewConsentForm).checkboxTitle}
                    placeholder={isAnotherLang ? (selectedQuestion.view as IQuestionViewConsentForm).checkboxTitle : ''}
                  />
                </Form.Item>
              )}
              {selectedQuestion.type === EQuestionType.nrs && (
                <NRSQuestion
                  selectedQuestion={selectedQuestion}
                  translatedSelectedQuestion={translatedSelectedQuestion}
                  form={form}
                  isAnotherLang={isAnotherLang}
                />
              )}
              {selectedQuestion.type === EQuestionType.period && (
                <div style={{ display: 'flex' }}>
                  <Form.Item name={[EQuestionForm.DefaultPeriod, defaultOption ? defaultOption.id : '']}>
                    <Checkbox disabled={!selectedQuestion.values[0] || isAnotherLang} checked={defaultOption?.isDefault}>
                      Default
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    style={{ opacity: defaultOption?.isDefault ? 1 : 0.5, width: '100%' }}
                    name={EQuestionForm.SelectedDefaultPeriod}
                    initialValue={defaultOption?.value}
                  >
                    <Select
                      disabled={!defaultOption?.isDefault || isAnotherLang}
                      className="questions__form_common-period-selector"
                      value={isAnotherLang ? translatedDefaultOption?.value : defaultOption?.value}
                    >
                      {isAnotherLang
                        ? translatedSelectedQuestion?.values?.map((value) => getOptions(value, isAnotherLang))
                        : selectedQuestion.values.map((value) => getOptions(value))}
                    </Select>
                  </Form.Item>
                </div>
              )}
              {selectedQuestion.type === EQuestionType.textarea && (
                <Form.Item
                  label="Placeholder"
                  name={EQuestionForm.Placeholder}
                  initialValue={(selectedQuestion.view as IQuestionViewTextArea).placeholder}
                >
                  <Input
                    value={(selectedQuestion.view as IQuestionViewTextArea).placeholder}
                    placeholder={isAnotherLang ? (selectedQuestion.view as IQuestionViewTextArea).placeholder : ''}
                  />
                </Form.Item>
              )}
              {selectedQuestion.type === EQuestionType.textOrSkip && (
                <>
                  <Form.Item
                    label={
                      (selectedQuestion.view as IQuestionViewTextOrSkip).inputType === ETextOrSkipQuestionInputType.Textarea
                        ? 'Text area'
                        : 'Text box'
                    }
                    name={EQuestionForm.Placeholder}
                    initialValue={(selectedQuestion.view as IQuestionViewTextOrSkip).placeholder}
                  >
                    <Input
                      value={(selectedQuestion.view as IQuestionViewTextOrSkip).placeholder}
                      placeholder={isAnotherLang ? (selectedQuestion.view as IQuestionViewTextOrSkip).placeholder : ''}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Checkbox text"
                    name={EQuestionForm.CheckboxText}
                    initialValue={(selectedQuestion.view as IQuestionViewTextOrSkip).checkboxText}
                  >
                    <Input
                      value={(selectedQuestion.view as IQuestionViewTextOrSkip).checkboxText}
                      placeholder={isAnotherLang ? (selectedQuestion.view as IQuestionViewTextOrSkip).checkboxText : ''}
                    />
                  </Form.Item>
                </>
              )}
              {selectedQuestion.type === EQuestionType.timePicker && (
                <Form.Item
                  label="Checkbox text"
                  name={EQuestionForm.CheckboxText}
                  initialValue={(selectedQuestion.view as IQuestionViewTimePicker).checkboxText}
                >
                  <Input
                    value={(selectedQuestion.view as IQuestionViewTimePicker).checkboxText}
                    placeholder={isAnotherLang ? (selectedQuestion.view as IQuestionViewTimePicker).checkboxText : ''}
                  />
                </Form.Item>
              )}
              {!isAnotherLang && (
                <>
                  <div className="questions__form_settings-subTitle">Relations</div>
                  {selectedQuestion.parents && selectedQuestion.parents.length < 2 && (
                    <div className="questions__form_settings-btn-wrapper">
                      <Button disabled={selectedQuestionIndex < 0} className="questions__form_settings-btn" onClick={addRelation}>
                        Add new relation
                      </Button>
                    </div>
                  )}
                  {selectedQuestion.parents?.map((parent: IQuestionRelationsDto, index) => {
                    return (
                      <div key={parent.id}>
                        <Form.Item
                          name={[EQuestionForm.RelationType, parent.id, 'value']}
                          label="Type of relation"
                          initialValue={parent.type}
                        >
                          <Select value={getRelationTypeValue(index, parent)} disabled={index === 1}>
                            <Option value={ERelationType.Visibility}>Visibility</Option>
                            <Option value={ERelationType.Placeholder}>Placeholder</Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name={[EQuestionForm.RelationParent, parent.id, 'value']}
                          label="Answer in question"
                          initialValue={parent.parentQuestionId}
                          help={dependencyConflicts.includes(selectedQuestion.label) && 'Dependency error due to questions order'}
                          validateStatus={dependencyConflicts.includes(selectedQuestion.label) ? 'error' : ''}
                        >
                          <Select value={parent.parentQuestionId}>
                            {questionsCopy?.slice(0, selectedQuestionIndex).map((question: IQuestionDto) => (
                              <Option key={question.id} value={question.id}>
                                {question.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        {parent.type === ERelationType.Visibility && (
                          <>
                            <Form.Item
                              name={[EQuestionForm.RelationCondition, parent.id, 'value']}
                              label="Predicate"
                              initialValue={parent.value?.condition}
                            >
                              <Select value={parent.value?.condition}>
                                {visibilityPredicatesHelper(
                                  questionsCopy?.find((question: IQuestionDto) => question.id === parent.parentQuestionId)
                                )}
                              </Select>
                            </Form.Item>
                            {parent.parentQuestionId.length > 0 &&
                              visibilityParentValuesHelper(
                                parent,
                                questionsCopy?.find((question: IQuestionDto) => question.id === parent.parentQuestionId),
                                parent.id
                              )}
                          </>
                        )}
                        <div className="questions__form_settings-btn-wrapper">
                          <Button
                            className="questions__form_settings-btn"
                            style={{ color: 'red' }}
                            onClick={() => removeRelation(parent.id)}
                          >
                            Remove relation
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

export const Questions = communicationUI.injector(
  communicationAssessments.injector(communicationQuestions.injector(communicationTranslations.injector(QuestionsComponent)))
);
