import React, { useCallback, useMemo } from 'react';
import { Button, PageHeader, Upload } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RcFile, UploadProps } from 'antd/lib/upload/interface';
import { clearCreds } from '@axmit/axios-patch-jwt';
import { ERoutes } from 'common/models/routesModel';
import { useCurrentPath } from 'common/hooks/useCurrentPath';
import { communicationUI, IUIConnectedProps } from 'entities/UI/ui.communication';
import { communicationAssessments, IAssessmentsConnectedProps } from 'entities/Assessments/assessments.communication';
import { communicationQuestions, IQuestionsConnectedProps } from 'entities/Questions/questions.communication';
import { EActionType } from 'entities/UI/ui.models';

type AllProps = IAssessmentsConnectedProps & IUIConnectedProps & IQuestionsConnectedProps;

export const HeaderComponent: React.FC<AllProps> = (props) => {
  const {
    assessmentsModel,
    assessmentsCollection,
    getAssessmentsCollection,
    showUiAddAssessmentModal,
    uiFormState,
    deleteAssessmentsModel,
    showUiAddQuestionModal,
    showUiUnsavedItemsModal,
    exportAssessmentsModel,
    importAssessmentsModel,
  } = props;
  const { path, params } = useCurrentPath();
  const navigate = useNavigate();
  const isRootPath = useMemo(
    () =>
      path === ERoutes.Root ||
      path === ERoutes.AssessmentsList ||
      path === ERoutes.AssessmentsBundles ||
      path === ERoutes.AssessmentsBundlesVisiblity,
    [path]
  );
  const canSave = useMemo(
    () => uiFormState.data?.isUnique && !uiFormState.data.dependencyConflicts && !uiFormState.data?.isEmptyRequiredFields,
    [uiFormState]
  );
  const canSaveQuestion = useMemo(
    () => !uiFormState.data?.dependencyConflicts && !uiFormState.data?.isEmptyRequiredFields,
    [uiFormState]
  );

  const mapTitle = useCallback(() => {
    switch (path) {
      case ERoutes.AssessmentsList:
      case ERoutes.AssessmentPageGeneral:
      case ERoutes.AssessmentPageQuestions:
      case ERoutes.AssessmentPageEntries:
        return 'Assessments';
      case ERoutes.AssessmentsBundles:
      case ERoutes.AssessmentsBundleItem:
        return 'Bundles';
      case ERoutes.AssessmentsBundlesVisiblity:
        return 'Bundle Visibility';
      case ERoutes.Translations:
        return 'Translations';
      default:
        return null;
    }
  }, [path]);

  const mapSubtitle = useCallback(() => {
    switch (path) {
      case ERoutes.AssessmentPageGeneral:
      case ERoutes.AssessmentPageQuestions:
      case ERoutes.AssessmentPageEntries:
        return assessmentsModel.data?.name;

      default:
        return null;
    }
  }, [path, assessmentsModel, assessmentsCollection]);

  const removeAssessment = useCallback(() => {
    if (params.id) {
      deleteAssessmentsModel({ assessmentId: params.id })
        .then(() => getAssessmentsCollection({}))
        .then(() => navigate(ERoutes.AssessmentsList));
    }
  }, [params]);

  const addQuestion = useCallback(() => {
    if (uiFormState.data?.isChanged) {
      showUiUnsavedItemsModal({ actionType: EActionType.AddQuestion });
    } else {
      showUiAddQuestionModal();
    }
  }, [uiFormState.data]);

  const exportAssessmentToJSON = useCallback(() => {
    if (params.id) {
      exportAssessmentsModel({ assessmentId: params.id });
    }
  }, [params]);

  const importJSONProps = useMemo(
    (): UploadProps => ({
      accept: '.json',
      name: 'file',
      showUploadList: false,
      beforeUpload: (file: RcFile) => {
        importAssessmentsModel({ file }).then(() => getAssessmentsCollection({}));

        return false;
      },
    }),
    []
  );

  const handleLogout = () => {
    clearCreds();
    sessionStorage.clear();
    location.reload();
  };

  const renderHeaderButtons = useCallback(() => {
    switch (path) {
      case ERoutes.AssessmentsList:
        return (
          <div key="assessments_list_buttons">
            <Button onClick={() => showUiAddAssessmentModal()} type="primary">
              Add
            </Button>
            <Upload className="ml-6" {...importJSONProps}>
              <Button>Import from JSON</Button>
            </Upload>
          </div>
        );
      case ERoutes.AssessmentPageGeneral:
        return (
          <div key="general_page_buttons">
            {uiFormState?.data?.isChanged && (
              <Button type="primary" className="mr-6" form="form" htmlType="submit" disabled={!canSave}>
                Save
              </Button>
            )}
            <Button className="mr-6" onClick={exportAssessmentToJSON}>
              Export to JSON
            </Button>
            {/* <Button className="mr-6">Clone</Button> */}
            <Button onClick={removeAssessment} danger>
              Remove assessment
            </Button>
          </div>
        );
      case ERoutes.AssessmentPageQuestions:
        return (
          <div key="general_page_buttons">
            {uiFormState?.data?.isChanged && (
              <Button htmlType="submit" type="primary" className="mr-6" form="form" disabled={!canSaveQuestion}>
                Save
              </Button>
            )}
            <Button onClick={addQuestion} type="ghost">
              Add question
            </Button>
          </div>
        );
      default:
        return null;
    }
  }, [path, uiFormState, canSave, canSaveQuestion]);

  const handleBack = useCallback(() => {
    const navigateTo = ERoutes.AssessmentsList;

    if (uiFormState.data?.isChanged) {
      showUiUnsavedItemsModal({ actionType: EActionType.Navigate, navigateTo });
    } else {
      navigate(navigateTo);
    }
  }, [uiFormState]);

  return (
    <PageHeader
      ghost={false}
      title={mapTitle()}
      subTitle={mapSubtitle()}
      onBack={!isRootPath ? () => handleBack() : undefined}
      extra={[
        renderHeaderButtons(),
        <Button key="logout_button" onClick={handleLogout} className="ml-4" type="primary" danger>
          Logout
        </Button>,
      ]}
    />
  );
};

export const Header = communicationQuestions.injector(
  communicationUI.injector(communicationAssessments.injector(HeaderComponent))
);
