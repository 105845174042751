import React, { FC } from 'react';
import { Button, Input, Radio, RadioChangeEvent } from 'antd';
import { parseValueToHtml } from 'common/helpers/questions.helper';
import { EQuestionFormat } from 'common/models/questions';
import {
  IQuestionTranslationValue,
  IQuestionValue,
  IQuestionViewSingleChoice,
  QuestionWithTranslation,
} from 'entities/Questions/questions.model';

interface ISingleChoiceTypeProps {
  question: QuestionWithTranslation;
  className?: string;
  isAnotherLang?: boolean;
}

export const SingleChoiceType: FC<ISingleChoiceTypeProps> = ({ className, question, isAnotherLang }) => {
  const [selected, setSelected] = React.useState<string | null>(null);

  const getOtherOption = (id: string | undefined) => {
    const otherOption = question?.values.find((value) => value.id === id && value.isOther === true);
    const translatedOtherOption = question.translations?.values?.find((value) => value.questionValueId === otherOption?.id);
    return { isExist: !!otherOption, option: otherOption, translatedOption: translatedOtherOption };
  };
  const otherOption = question?.values.find((value) => value.isOther === true);
  const format = (question.view as IQuestionViewSingleChoice)?.format;

  const onChangeRadio = (e: RadioChangeEvent) => {
    setSelected(e.target.value);
  };

  const onClickButton = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setSelected(e.currentTarget.innerText);
  };

  const getValueById = (id: string) => {
    return question.values.find((value) => value.id === id)?.value;
  };

  const sortedTranslationsArray = React.useMemo(() => {
    const arr: IQuestionTranslationValue[] = [];
    question.values.forEach((item) => {
      question.translations?.values?.forEach((el) => {
        if (item.id === el.questionValueId) {
          arr.push(el);
        }
      });
    });
    return arr;
  }, [question.values, question.translations?.values]);

  return (
    <div>
      {format === EQuestionFormat.Button ? (
        <div className={className}>
          {isAnotherLang
            ? sortedTranslationsArray?.map((value) => {
                if (getOtherOption(value.questionValueId).isExist) {
                  return null;
                } else {
                  return (
                    <Button
                      key={value.questionValueId}
                      value={value.value ? value.value : getValueById(value.questionValueId)}
                      onClick={onClickButton}
                    >
                      {parseValueToHtml(value.value ? value.value : getValueById(value.questionValueId))}
                    </Button>
                  );
                }
              })
            : question.values.map((value: IQuestionValue) => {
                if (value.isOther === true) {
                  return null;
                } else {
                  return (
                    <Button key={value.id} value={value.value} onClick={onClickButton}>
                      {parseValueToHtml(value.value)}
                    </Button>
                  );
                }
              })}
          {getOtherOption(otherOption?.id).isExist && (
            <Button
              value={
                isAnotherLang && getOtherOption(otherOption?.id).translatedOption?.value
                  ? getOtherOption(otherOption?.id).translatedOption?.value
                  : otherOption?.value
              }
              onClick={onClickButton}
            >
              {parseValueToHtml(
                isAnotherLang && getOtherOption(otherOption?.id).translatedOption?.value
                  ? getOtherOption(otherOption?.id).translatedOption?.value
                  : otherOption?.value
              )}
            </Button>
          )}
          {otherOption && otherOption.value === selected && (
            <Input className="questions__form_common-period-input" placeholder="Please specify" />
          )}
        </div>
      ) : (
        <Radio.Group className={className} onChange={onChangeRadio}>
          {isAnotherLang
            ? sortedTranslationsArray?.map((value) => {
                if (getOtherOption(value.questionValueId).isExist) {
                  return null;
                } else {
                  return (
                    <Radio key={value.questionValueId} value={value.value ? value.value : getValueById(value.questionValueId)}>
                      {parseValueToHtml(value.value ? value.value : getValueById(value.questionValueId))}
                    </Radio>
                  );
                }
              })
            : question.values.map((value: IQuestionValue) => {
                if (value.isOther === true) {
                  return null;
                } else {
                  return (
                    <Radio key={value.id} value={value.value}>
                      {parseValueToHtml(value.value)}
                    </Radio>
                  );
                }
              })}
          {getOtherOption(otherOption?.id).isExist && (
            <Radio
              value={
                isAnotherLang && getOtherOption(otherOption?.id).translatedOption?.value
                  ? getOtherOption(otherOption?.id).translatedOption?.value
                  : otherOption?.value
              }
            >
              {parseValueToHtml(
                isAnotherLang && getOtherOption(otherOption?.id).translatedOption?.value
                  ? getOtherOption(otherOption?.id).translatedOption?.value
                  : otherOption?.value
              )}
            </Radio>
          )}
          {otherOption && otherOption.value === selected && (
            <Input className="questions__form_common-period-input" placeholder="Please specify" />
          )}
        </Radio.Group>
      )}
    </div>
  );
};
